import { createContext, useState } from "react";

const BookModalContext = createContext();

export function BookModalProvider ({children}) {
    const [chosenBook, setChosenBook] = useState("EW001")
    const chooseBook = ( id ) => {
        // console.log(id)
        setChosenBook(id)
        console.log(chosenBook)
    }

    return(
        <BookModalContext.Provider value={{ chooseBook, chosenBook }}>
            {children}
        </BookModalContext.Provider>
    );
}

export default BookModalContext;