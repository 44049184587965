import React from 'react'
import { useLocation } from 'react-router-dom'
// import './pagenotfound.css'

const PageNotFound = () => {
    let location = useLocation()

	return(
		<section className="ftco-section">
			<div className="container">
                <div>Sorry the page has not been found.</div>
                {location.pathname}
            </div>
		</section>
	);
}

export default PageNotFound;