import React from 'react'
import './Style.css'
import Logo from './../images/logo/ewlogo1.jpg'
import {
    Routes,
    Route,
    Link,
    NavLink
} from 'react-router-dom'
// Pages
import Home from './pages/Home'
import AboutEdmund from './pages/AboutEdmund'
// To add temporarily.
import EmptyAboutComponent from '../components/about/profile/accordion/EmptyAboutComponent'
import AccomplishmentDetails from '../components/about/profile/AccomplishmentDetails'
import EdmundsBooks from './pages/EdmundsBooks'
import BooksCollection from './../components/books/BooksCollection'
import OrderBook from './../components/contact/OrderBook'
import EdmundsTalks from './pages/EdmundsTalks'
import BookEdmund from './pages/BookEdmund'
import EdmundsGallery from './pages/EdmundsGallery'
import GalleryAlbums from './../components/gallery/selectalbum/GalleryAlbums'
import EdmundsAlbumGallery from './../components/gallery/viewalbum/EdmundsAlbumGallery'
import ContactEdmund from './pages/ContactEdmund'
import PageNotFound from './pages/PageNotFound'
// import SmallLoader from './../components/loading/SmallLoader';
import $ from 'jquery'
// Application Context API.
import { BookModalProvider } from './../context/BookModalContext'

const handleClick = () => {
    var burgerMenu = function(event) {

      // let menuButton = document.querySelector('js-colorlib-nav-toggle');
      // let websiteBody = document.querySelector('body');
      // // menuButton.addEventListener('click', function(e) {
      //   if (websiteBody.classList.contains('offcanvas')){
      //     menuButton.classList.remove('active');
      //     websiteBody.classList.remove('offcanvas');
      //   }else{
      //     menuButton.classList.add('active');
      //     websiteBody.classList.add('offcanvas');
      //   }
      // // })

        // $('.js-colorlib-nav-toggle').on('click', function(event){
        //   event.preventDefault();
          var $this = $(this);
      
          if ($('body').hasClass('offcanvas')) {
            $this.removeClass('active');
            $('body').removeClass('offcanvas');	
          } else {
            $this.addClass('active');
            $('body').addClass('offcanvas');	
          }
        // });
      };
      burgerMenu();
      
      // Click outside of offcanvass
      var mobileMenuOutsideClick = function() {
      
        $(document).click(function (e) {
          var container = $("#colorlib-aside, .js-colorlib-nav-toggle");
          if (!container.is(e.target) && container.has(e.target).length === 0) {
      
            if ( $('body').hasClass('offcanvas') ) {
      
              $('body').removeClass('offcanvas');
              $('.js-colorlib-nav-toggle').removeClass('active');
          
            }
            
          }
        });
      
        $(window).scroll(function(){
          if ( $('body').hasClass('offcanvas') ) {
      
              $('body').removeClass('offcanvas');
              $('.js-colorlib-nav-toggle').removeClass('active');
          
            }
        });
      
      };
      mobileMenuOutsideClick();
}

const PageFormat = () => {
    return(
      <BookModalProvider>
        <div id="colorlib-page">
          {/* Sidebar Start */}
          <a className="js-colorlib-nav-toggle colorlib-nav-toggle" onClick={handleClick}><i></i></a>
          <aside id="colorlib-aside" role="complementary" className="js-fullheight text-center">
            <div className="author-img" style={{backgroundImage: "url("+Logo+")"}}></div>
            <h1 id="colorlib-logo"><Link to="/">Edmund Walusimbi{/*<span></span>*/}</Link></h1>
            <nav id="colorlib-main-menu" role="navigation">
              <div id="navbar" className="collapse">
                <ul>
                  <li><NavLink className={({ isActive }) => (isActive ? 'colorlib-active' : '')}/*activeStyle={{ backgroundColor: '#88e' }}*/ to="/">Home</NavLink></li>
                  <li><NavLink className={({ isActive }) => (isActive ? 'colorlib-active' : '')} to="/About-Edmund">About Edmund</NavLink></li>
                  <li><NavLink className={({ isActive }) => (isActive ? 'colorlib-active' : '')} to="/Books">Edmund`s Books</NavLink></li>
                  <li><NavLink className={({ isActive }) => (isActive ? 'colorlib-active' : '')} to="/Speaker">Edmund`s Talks</NavLink></li>
                  <li><NavLink className={({ isActive }) => (isActive ? 'colorlib-active' : '')} to="/Book-Edmund">Book Edmund to Speak</NavLink></li>
                  <li><NavLink className={({ isActive }) => (isActive ? 'colorlib-active' : '')} to="/Gallery">Edmund`s Gallery</NavLink></li>
                  <li><NavLink className={({ isActive }) => (isActive ? 'colorlib-active' : '')} to="/Contact">Contact Edmund</NavLink></li>
                </ul>
              </div>
            </nav>
            <div className="colorlib-footer">
              <ul>
                <li><a href="https://wa.me/+393515694644"><i className="icon-whatsapp"></i></a></li>
                <li><a href="https://www.facebook.com/edmundwalusimbi/"><i className="icon-facebook"></i></a></li>
                <li><a href="https://twitter.com/EdmundWalusimbi?s=09"><i className="icon-twitter"></i></a></li>
                <li><a href="https://www.instagram.com/edmundwalusimbi?r=nametag"><i className="icon-instagram"></i></a></li>
                <li><a href="https://www.youtube.com/channel/UCuUt0FlVGBiBGVFlSEVusEQ"><i className="icon-youtube"></i></a></li>
                <li><a href="https://www.linkedin.com/in/edmund-walusimbi-bb93a7a1"><i className="icon-linkedin"></i></a></li>
              </ul>
            </div>
          </aside>
          {/* Sidebar End */}
          <div id="colorlib-main">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="About-Edmund" element={<EmptyAboutComponent />}>
                <Route path="" element={<AboutEdmund />} />
                <Route path=":keyId" element={<AccomplishmentDetails />} />
              </Route>
              <Route path="Books" element={<EdmundsBooks />}>
                <Route path="" element={<BooksCollection />} />
                <Route path=":keyId" element={<OrderBook />} />
              </Route>
              <Route path="Speaker" element={<EdmundsTalks />} />
              <Route path="Book-Edmund" element={<BookEdmund />} />
              <Route path="Gallery" element={<EdmundsGallery />}>
                <Route path="" element={<GalleryAlbums />} />
                <Route path=":keyid" element={<EdmundsAlbumGallery />} />
              </Route>
              <Route path="Contact" element={<ContactEdmund />} />
              <Route path='*' element={<PageNotFound />} />
            </Routes>
          </div>
        </div>
      </BookModalProvider>
    );
}

export default PageFormat;