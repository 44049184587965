import React, {useContext} from 'react'
import {
	Link//,
	// useLocation
} from 'react-router-dom'
// import BookPreview from './modals/BookPreview'
// import BookDetails from './modals/BookDetails'
import BookModalContext from '../../context/BookModalContext'

const Book = (props) => {

	const { chooseBook } = useContext(BookModalContext)
	const pageLocation = window.location.pathname
	let path
	if(pageLocation === "/"){
		path = "Books/"+props.keyId
		// console.log(path)
	}else{
		path = props.keyId
		// console.log(path)
	}

	return(
			<div className="col-md-4 col-sm-6 animate-box" data-animate-effect="fadeInRight">
				<div className="blog-entry">
					<Link to={path} class="blog-img"><img src={props.bookImage} className="img-responsive" alt={props.title}/></Link>
					<div className="desc">
						<h3 className="text-center"><strong>{props.title}</strong></h3>
						<p className="mb-4">
							<h5><strong>Publish date:</strong> {props.published}</h5>
							<h5><strong>Target audience:</strong> {props.audience}</h5>
						</p>
						<div className="profile-card-5 text-center">
							{/* <button onClick={() => chooseBook(props.bookId)} className="btn btn-primary" data-toggle="modal" data-target="#bookDetailModal">Book Details</button>
							<button onClick={() => chooseBook(props.bookId)} className="btn btn-primary" data-toggle="modal" data-target="#bookPreviewModal">Read Preview</button> */}
							<br/><Link to={path} className="btn btn-primary">Make an Order Now</Link>
						</div>
					</div>
				</div>
				{/* <BookPreview bookPreview={props.preview}/>
				<BookDetails bookDetails={props.details}/> */}
			</div>
	);
}

export default Book;