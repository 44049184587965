import React, {useContext} from 'react'
import { useParams } from 'react-router-dom'
import {Helmet} from "react-helmet"
import BookModalContext from '../../context/BookModalContext'
import BookOrderForm from './forms/BookOrderForm'
import './contact.css'
import './../gallery/albumscss/gallery_grid.css'
import './../gallery/albumscss/gallery-grid.css'
import BookPreview from './../books/modals/BookPreview'
import BookDetails from './../books/modals/BookDetails'
import { BookList } from '../books/BookList'

const OrderBook = () => {
    const { chooseBook } = useContext(BookModalContext)
    const { keyId } = useParams()
    const selectedBook = BookList.filter(book => book.keyvalue === keyId)
    let ugPrice = parseInt(selectedBook[0].ugx)

	return(
            <div>
                <Helmet>
                    <title>{/*Edmund Walusimbi | */}{keyId}</title>
                    <meta name="author" content="Edmund Walusimbi" />
                    <meta name="description" content={selectedBook[0].bookdesc} />
                    <meta property="og:image" content={selectedBook[0].image} />
                </Helmet>
                <section className="ftco-section">
                    <div className="container">
                        <div className="row justify-content-center mb-5 pb-2">
                            <div className="col-md-12 heading-section ftco-animate">
                                <h2 className="mb-4">{selectedBook[0].booktitle}.</h2>
                                <p><strong>Order yourself a Copy.</strong></p>
                                {/* <p>Please fill in the form below to make an order for <strong>{selectedBook[0].booktitle}</strong>: -</p> */}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div class="tz-gallery">
                                    <div className="lightbox blog-img">
                                        <img src={selectedBook[0].image} className="img-responsive" alt={selectedBook[0].booktitle} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 col-md-push-4">
                                <div className="row order">
                                    {/* <h3 className="page-description">propsname</h3> */}
                                    <p className="mb-4">
                                        <h5><strong>Price:</strong> Ugx. {ugPrice.toLocaleString('en-US')}/= ({selectedBook[0].usd} USD)</h5>
                                        <h5><strong>Target audience:</strong> {selectedBook[0].targetaudience}</h5>
                                        <h5><strong>Date of publication:</strong> {selectedBook[0].publicationdate}</h5>
                                        {/* <h4><strong>Quantity:</strong> 0</h4> */}
                                        {/* <h4><strong>Total: </strong> </h4> */}
                                    </p>
                                    {/* <div className="profile-card-5 text-center">
                                        <button onClick={() => chooseBook(selectedBook[0].id)} className="btn btn-primary" data-toggle="modal" data-target="#bookDetailModal">Book Details</button>
                                        <button onClick={() => chooseBook(selectedBook[0].id)} className="btn btn-primary" data-toggle="modal" data-target="#bookPreviewModal">Read Preview</button>
                                    </div> */}
                                </div>
                            </div>					
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="about-desc">
                                    <span className="heading-meta">Book Preview.</span>
                                    {selectedBook[0].bookpreview}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12" style={{margin: 'auto', paddingTop: 30}}>
                                <p>Fill the form below to directly make an order for <strong>{selectedBook[0].booktitle}</strong>.</p>
                                <BookOrderForm bookTitle={selectedBook[0].booktitle} />
                            </div>
                        </div>
                    </div>
                </section>
                <BookPreview/>
                <BookDetails/>
            </div>
	);
}

export default OrderBook;