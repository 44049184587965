import React, { useContext } from 'react'
import { BookList } from '../BookList';
import BookModalContext from '../../../context/BookModalContext';

const BookPreview = () =>{
	const { chosenBook } = useContext(BookModalContext);
	const selectedBook = BookList.filter(book => book.id === chosenBook)

	return(
			<div id="bookPreviewModal" className="modal fade" role="dialog">
				<div className="modal-dialog modal-lg">
			
				<div className="modal-content">
					<div className="modal-header">
					<button type="button" className="close" data-dismiss="modal">&times;</button>
					</div>
					<div className="modal-body">
					
					<section id="skills" className="colorlib-about" data-section="talks">
						<div className="colorlib-narrow-content">
							<div className="row">
								<div className="col-md-12">
									<div className="row row-bottom-padded-sm animate-box" data-animate-effect="fadeInLeft">
										<div className="col-md-12">
											<div className="about-desc">
                    							<span className="heading-meta">Edmund`s Books (Book Preview).</span>
												<h2 className="colorlib-heading"><b>{selectedBook[0].booktitle}</b></h2>
												{selectedBook[0].bookpreview}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
						
					</div>
					<div className="modal-footer">
					<button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
					</div>
				</div>
			
				</div>
			</div>
	);
}

export default BookPreview;