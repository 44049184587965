import React from 'react'
import GalleryCarousel from './GalleryCarousel'
import { Link } from 'react-router-dom'

const EdmundsGallerySummary = () => {
	return(
		<div>
			<div className="row justify-content-center mb-5 pb-2">
				<div className="col-md-12 heading-section ftco-animate">
					<h2 className="mb-4">My Gallery.</h2>
					<p><strong>Take a trip down memory lane to experience my journey up until now.</strong></p>
				</div>
			</div>
			<div className="row">
				<GalleryCarousel />
			</div>
			<div className="row animate-box" data-animate-effect="fadeInLeft">
				<div className="col-md-12 mt-4 person text-center">
					<div className="profile-card-5">
						<Link to="/Gallery" className="btn btn-primary">View my Gallery</Link>
						<br/><p>.</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default EdmundsGallerySummary;
