import React from 'react'

export let Accomplishments = [
  {
    keyvalue: "C.E.O-The-Mentorship-Class",
    position: "Edmund as the C.E.O of The Mentorship Class.",
    intro: [<><p>Edmund took on the contract of C.E.O with a vision of <b>Putting The Mentorship Class on the map of Africa</b>.<br />Below are the milestones that were archived during the first year of his stewardship;</p></>],
    contents: [
      {
        title: "The Tenderfoot Program.",
        work: [<><p>Based on the demand of our alumni parents to provide the younger children with our mentorship, we started the first ever <b>Tenderfoot class (consisting of mentees aged sixteen years)</b>.</p></>],
      },
      {
        title: "The innovation skills global challenge",
        work: "The Mentorship Class was among the top four finalists in the third innovation skills global challenge organized by the International Labour Organization. We were awarded a certificate signed by Mr. Srinivas Reddy Chief of the International Labour Organization Skills and Employability Branch as finalists.",
      },
      {
        title: "Partnership with Stanbic Bank.",
        work: "We secured a partnership with Stanbic Bank in providing us with their venue premises for The Mentorship Class 2021 at Umoja House.",
      },
      {
        title: "Successfully established The Mentorship Class online portal.",
        work: [<>
          <p>In <b>May 2021</b>, during the second wave of the COVID-19 pandemic, we were able to successfully ran <b>the fifth cohort</b> of The Mentorship Class despite all the uncertainties brought about by the pandemic. In order to do this, we established an online portal that we used to conduct the programs that enabled us to mentor a total of <b>89 mentees</b>.</p>
          {/* <img src="https://www.edmundwalusimbi.com/react/images/about/The-Mentorship-Class/The-Mentorship-Class-online-portal.jpg" width="400px" /> */}
        </>],
      },
      {
        title: "Hosted the British High Commissioner to Uganda.",
        work: "We also hosted the British High Commissioner to Uganda Her Excellency Kate Airey during the graduation event for our mentees on 26th November 2021.",
      }
    ]
  },
  {
    keyvalue: "Makerere-Engineering-Society-President",
    position: "Edmund as the President of the Makerere Engineering Society.",
    intro: [<><p>Edmund`s term as the president of the Makerere Engineering Society commenced during the lockdown of the second wave of the covid-19 pandemic. Amidst all the challenges, they successfully achieved these milestones under the theme, <b>Leading in times of uncertainty</b>;</p></>],
    contents: [
      {
        title: "The MES Incubation Program.",
        work: "We organized the first MES Incubation program during the lockdown as a one month virtual training program designed to support young innovators with support services like mentorship and sessions for them to become more resilient with their innovations.",
      },
      {
        title: "The MES Insider magazine.",
        work: [<>
          <p>We launched the first edition of a magazine edition called the <b>MES Insider</b> featuring the history of the School of Engineering, student innovations, and information about academia. This was launched by the <b>Minister of State for Mineral Development, Hon. Dr. Peter T. Lokeris</b> during our golden jubilee celebrations.</p>
          <p><a href='https://drive.google.com/file/d/1Zr0IiB0KNGH2QnR2ajGYlsCExEQFSFiU/view?usp=sharing'>Click here</a> to have a look at the <b>first edition of The MES Insider Magazine</b>.</p>
        </>],
      },
      {
        title: "Positioning yourself for the engineering job market.",
        work: "We successfully organized the first student led webinar workshop that hosted the President of Uganda Institute of Professional Engineers Eng. Ben Kyemba, Acting Director of Uganda Communication Commission Eng. Irene Kaggwa, CEO of Kiira Motors Corporation Prof. Paul Isaac Musasizi, and Head of Capital Projects for UMEME Limited Eng. Serwadda Isaac. These professionals shared about the theme of “Positioning yourself for the engineering job market” that impacted over 5,000 students virtually. The second webinar we organized was the MES-KMC Webinar where we hosted the Director of Marketing and Sales at Kiira Motors Corporation Mr. Allan Muhumuza who shared about how the students could enroll for opportunities within this company.",
      },
      {
        title: "The Golden Jubilee celebrations for the engineering society.",
        work: "We also organized the golden jubilee celebrations for the engineering society in conjunction with the 10th annual Open day for students to exhibit their innovative projects during which we hosted the Minister of Science Technology and Innovation Hon. Dr. Monica Musenero as the chief guest.",
      },
      {
        title: "Linking the Engineering Students to the University Administration.",
        work: "We also served as a communication channel between the students and university administration during the lockdown in 2021. As student leaders, we represented the students in different academic board meetings concerning the resumption of online learning and examinations.",
      }
    ]
  }
];

// module.exports = {
//   Accomplishments
// }