import React from 'react'
import ProgressiveImage from 'react-progressive-graceful-image'
import LazyLoad from 'react-lazyload'
import { Link } from 'react-router-dom'
// import physicalTalksImage from './../images/SelectGallery/Edmundatccc2019.jpg'
// import milestonesImage from './../images/SelectGallery/teamleader8.jpg'
// import virtualTalksImage from './../images/SelectGallery/yamba.jpg'

const EdmundsAlbumSelect = (props) => {
	return(
		<div className="col-md-4 col-sm-6 animate-box" data-animate-effect="fadeInLeft">
            <div className="blog-entry">
                <Link className="lightbox blog-img" to={"/Gallery/" + props.keyid}>
                <LazyLoad once><img src={props.image} className="img-responsive" alt={props.name} /></LazyLoad>
                </Link>
                <div className="desc">
                    <h3 className="page-description"><Link to={"/Gallery/" + props.keyid}>{props.name}</Link></h3>
                    <div className="profile-card-5 text-center">
                        <br/>
                        <Link to={"/Gallery/" + props.keyid} className="btn btn-primary">View Album</Link>
                    </div>
                </div>
            </div>
        </div>
	);
}

export default EdmundsAlbumSelect;