import React from 'react'

const SocialEntrepreneurProfile = () => {
	return(
		<div>
            <div className="row justify-content-center mb-5 pb-2">
                <div className="col-md-12 heading-section ftco-animate">
                    <h2 className="mb-4">Edmund as a Social Entrepreneur.</h2>
                    {/* <p><strong>Edmund as a Social Entrepreneur.</strong></p> */}
                </div>
            </div>
            <div className="row">
                <div className="col-md-8 col-md-push-1">
                    <p><b>Edmund</b> is also a social entrepreneur and Founder of an organization known as <b>The Secret Initiative</b> which he founded in his senior six vacation in the year, <b>2017</b>.</p>
						<p className="mb-4">
							As a social entrepreneur, he has: -
						<ul>
							<li>Founded <b>The Secret Initiative</b>.</li>
							<li>Alumni of the <b>Young African Leaders Initiative (YALI EA RLC)</b>.</li>
							<li>Authored a book named <b>The Secret To Getting Government Sponsorship on Merit</b>.</li>
						</ul>
					</p>
					<p>
						For more information on <b>The Secret Initiative</b>, <a href='https://youtu.be/nd3JLCJo638'>follow this link</a>.
					</p>
                </div>
                <div className="col-md-4">
                    <img src="https://www.edmundwalusimbi.com/react/images/about/socialentrepreneur1.jpg" style={{borderRadius: 5, width: 370}} className="img-responsive" />
                </div>
            </div>
			<div className="row animate-box" data-animate-effect="fadeInLeft">
				<div className="col-md-12 mt-4 person text-center">
					<div className="profile-card-5">
						{/* <a href="#" className="btn btn-primary">VISIT THE SECRET INITIATIVE OFFICIAL WEBSITE</a><br/> */}
                        <p>.</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SocialEntrepreneurProfile;