import React from 'react'
import './../albumscss/gallery_grid.css'
import './../albumscss/gallery-grid.css'

const EdmundViewImage = (props) => {
	return(
		<div className="col-md-4 col-sm-6 animate-box" data-animate-effect="fadeInLeft">
            <div className="blog-entry">
                <a className="blog-img lightbox" href={props.image}>
					<img src={props.image} className="img-responsive" alt={props.name} height="270" width="auto" />
				</a>
                <div className="desc">
                    <h3 className="page-description" style={{color: '#000'}}>{props.name}</h3>
                </div>
            </div>
        </div>
	);
}

export default EdmundViewImage;
