import React from 'react'
import ContactForm from './forms/ContactForm'
import './contact.css'

const Contact = () => {
	return(
		<div>
			<div className="row justify-content-center mb-5 pb-2">
				<div className="col-md-12 heading-section ftco-animate">
					<h2 className="mb-4">Get in Touch.</h2>
					<p><strong>CONTACT EDMUND.</strong></p>
					<p>In case you are interested in getting in touch with <strong>Edmund</strong>, please use the section below: -</p>
				</div>
			</div>
			{/* <div id="contactrowlist">
				<div id='contactitem'>
					<div className="colorlib-feature colorlib-feature-sm">
						<div className="colorlib-icon">
							<i className="icon-facebook"></i>
						</div>
					</div>
					<div className='contactLabel'><p>Facebook</p></div>
				</div>
				<div className='contactitem'>
					<div className="colorlib-feature colorlib-feature-sm">
						<div className="colorlib-icon">
							<i className="icon-twitter"></i>
						</div>
					</div>
					<div className='contactLabel'><p>.<br/>.<br/>.<br/>Twitter</p></div>
				</div>
				<div className='contactitem'>
					<div className="colorlib-feature colorlib-feature-sm">
						<div className="colorlib-icon">
							<i className="icon-instagram"></i>
						</div>
					</div>
					<p>Instagram</p>
				</div>
				<div className='contactitem'>
					<div className="colorlib-feature colorlib-feature-sm">
						<div className="colorlib-icon">
							<i className="icon-youtube"></i>
						</div>
					</div>
					<p>YouTube</p>
				</div>
				<div className='contactitem'>
					<div className="colorlib-feature colorlib-feature-sm">
						<div className="colorlib-icon">
							<i className="icon-linkedin"></i>
						</div>
					</div>
					<div className="contactitem colorlib-text">
						<p><a href="mailto:walusimbiedmund88@gmail.com"><b>LinkedIn</b></a></p>
					</div>
					<p>LinkedIn</p>
				</div>
			</div> */}
			<div className="row">
				<div className="col-md-5">
					<div className="colorlib-feature colorlib-feature-sm animate-box" data-animate-effect="fadeInLeft">
						<div className="colorlib-icon">
							<i className="icon-globe"></i>
						</div>
						<div className="colorlib-text">
							<p><a href="mailto:walusimbiedmund88@gmail.com"><b>walusimbiedmund88@gmail.com</b></a></p>
						</div>
					</div>
					<div className="colorlib-feature colorlib-feature-sm animate-box" data-animate-effect="fadeInLeft">
						<div className="colorlib-icon">
							<i className="icon-phone"></i>
						</div>
						<div className="colorlib-text">
							<p><a href="tel://+39 351 569 4644"><b>+39 351 569 4644</b></a></p>       {/*Previous number: +256 758 717505*/}
						</div>
					</div>
				</div>
				<div className="col-md-7 col-md-push-4">
					<div className="row">
						<ContactForm />					
					</div>
				</div>					
			</div>
		</div>
	);
}

export default Contact;