import React from 'react'
import Banner from '../../components/banner/Banner'
import Talks from './../../components/talks/Talks'

const EdmundsTalks = () => {
	return(
		<div>
            <Banner
                image="https://www.edmundwalusimbi.com/images/personalpics/BDW_5178.jpg"
                intro={<>
                    <h2 className="subheading">Edmund Walusimbi</h2>
					<h1 className="mb-4">As a Speaker.</h1>
                </>}
            />
            <section className="ftco-section">
				<div className="container">
					<Talks />
				</div>
			</section>
		</div>
	);
}

export default EdmundsTalks;