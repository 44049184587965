import React from 'react'
import { Outlet } from 'react-router-dom'

const EdmundsBooks = () => {
	return(
		<>
            <Outlet />
		</>
	);
}

export default EdmundsBooks;