import React from 'react'
import Banner from '../../components/banner/Banner'
import AboutSummary from './../../components/about/AboutSummary'
import Books from './../../components/books/Books'
import TalkSummary from './../../components/talks/TalkSummary'
// import SpeakerSummary from '../../components/talks/SpeakerSummary'
// import Booking from './../../components/contact/Booking'
import EdmundsGallerySummary from './../../components/gallery/EdmundsGallerySummary'
import Contact from './../../components/contact/Contact'
// import './banner.css'

const Home = () => {
	return(
		<div>
			<Banner
				image="https://www.edmundwalusimbi.com/react/images/banner/EdmundWalusimbi.jpg"
				intro={<>
					<h2 className="subheading">Hello I'm</h2>
					<h1 className="mb-4">Edmund Walusimbi</h1>
					{/* <p><a href="#" className="btn-custom">Read My CV <span className="ion-ios-arrow-forward"></span></a></p> */}
				</>}
			/>
			<section className="ftco-section">
				<div className="container">
					<AboutSummary />
					<Books />
					<TalkSummary />
					{/* <SpeakerSummary /> */}
					{/* <BookEdmund /> */}
					<EdmundsGallerySummary />
					<Contact />
				</div>
			</section>
		</div>
	);
}

export default Home;