import React, { Component } from 'react'
import axios from 'axios'
import swal from 'sweetalert'

class ContactForm extends Component {
	constructor() {
		super()
		this.state={
			fname: "",
			surname: "",
			email: "",
			telephone: "",
			subject: "",
			message: "",
			time: "",
			date: ""
		}
		this.handleChange = this.handleChange.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
	}

	handleChange(event) {
		const {name,value} = event.target
		this.setState({
			[name]: value
		})
	}

	handleSubmit(event) {
		event.preventDefault()
		axios.post('https://edmundwalusimbi.com/api/public/api/feedback', {
			fname: this.state.fname,
			surname: this.state.surname,
			email: this.state.email,
			telephone: this.state.telephone,
			subject: this.state.subject,
			message: this.state.message,
			time: new Date().toLocaleTimeString(),
			date: new Date().toLocaleDateString()
		})
		.then( (response) => {
			// console.log(response.data.message)
			swal("Response:", response.data.message, "success")

			// this.setState({
			// 	fname: "",
			// 	surname: "",
			// 	email: "",
			// 	telephone: "",
			// 	subject: "",
			// 	message: "",
			// 	time: "",
			// 	date: ""
			// })
		})
		.catch( (error) => {
			console.log(error)
			// swal("Error:", error.message, "error")

			if(error.message === "Network Error"){
				swal("Error:", "We are having trouble contacting Edmund. Please check your internet connection and try again.", "error")
			}//else{
			// 	swal("Error:", "The error is here.", "error")
			// }
		})

		axios.post('https://edmundwalusimbi.com/api/public/api/feedbacknotification', {
			fname: this.state.fname,
			surname: this.state.surname,
			email: this.state.email,
			telephone: this.state.telephone,
			subject: this.state.subject,
			message: this.state.message,
			time: new Date().toLocaleTimeString(),
			date: new Date().toLocaleDateString()
		})
		.then( (response) => {
			// console.log(response.data.message)
			swal("Response:", response.data.message, "success")

			// Clearing the form after submitting successfully.
			this.setState({
				fname: "",
				surname: "",
				email: "",
				telephone: "",
				subject: "",
				message: "",
				time: "",
				date: ""
			})
		})
		.catch( (error) => {
			// console.log(error)
			swal("Error:", error.message, "error")

			if(error.message === "Network Error"){
				swal("Error:", "We are having trouble contacting Edmund. Please check your internet connection try again.", "error")
			}
		})
	}

	render() {
		return(
			<div className="col-md-10 col-md-offset-1 col-md-pull-1 animate-box" data-animate-effect="fadeInRight">
				<form id="contacted" name="contacted" onSubmit={this.handleSubmit}>
					<div className="form-group">
						<input
							type="text"
							id="fname"
							name="fname"
							value={this.state.fname}
							className="form-control"
							placeholder="First Name"
							onChange={this.handleChange}
							required
						/>
					</div>
					<div className="form-group">
						<input
							type="text"
							id="surname"
							name="surname"
							value={this.state.surname}
							className="form-control"
							placeholder="Surname"
							onChange={this.handleChange}
							required
						/>
					</div>
					<div className="form-group">
						<input
							type="text"
							id="email"
							name="email"
							value={this.state.email}
							className="form-control"
							placeholder="Email Address"
							onChange={this.handleChange}
							required
						/>
					</div>
					<div className="form-group">
						<input
							type="number"
							id="telephone"
							name="telephone"
							value={this.state.telephone}
							className="form-control"
							placeholder="Telephone / WhatsApp contact"
							onChange={this.handleChange}
							required
						/>
					</div>
					<div className="form-group">
						<input
							type="text"
							id="subject"
							name="subject"
							value={this.state.subject}
							className="form-control"
							placeholder="Subject"
							onChange={this.handleChange}
							required
						/>
					</div>
					<div className="form-group">
						<textarea
							id="message"
							name="message"
							value={this.state.message}
							cols="30"
							rows="7"
							className="form-control"
							placeholder="Message"
							onChange={this.handleChange}
							required
						/>
					</div>
					<div className="form-group">
						<button className="btn btn-primary btn-send-message">Send Message</button>
					</div>
				</form>
			</div>
		);
	}
}

export default ContactForm;