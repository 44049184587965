import React, { useRef } from 'react'

const AccordionItem = ({ faq, onToggle, active }) => {
    const { title, work } = faq;
    const contentEl = useRef();

	return(
        <li className={`accordion_item ${active ? "active" : ""}`}>
            <button className="button" onClick={onToggle}>
                {title}
                <span className="control">{active ? "—" : "+"} </span>
            </button>
            <div ref={contentEl} className="answer_wrapper" style={ active ? { height: contentEl.current.scrollHeight } : { height: "0px" } }>
                <div className="answer">{work}</div>
            </div>
        </li>
    );
}

export default AccordionItem;