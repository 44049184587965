import { React, useEffect } from 'react'
import $ from 'jquery'
import './Banner.css'
// import './BannerStyle.js'

const Banner = (props) => {

	useEffect(() => {
		$('.js-fullheight').css('height', $(window).height());
		$(window).resize(function(){
			$('.js-fullheight').css('height', $(window).height());
		});
	});

	return(
		<div className="hero-wrap js-fullheight" style={{backgroundImage: `url(${props.image})`}} data-stellar-background-ratio="0.5">
			<div className="overlay"></div>
			<div className="js-fullheight d-flex justify-content-center align-items-center">
				<div className="col-md-8 text text-center">
					<div className="desc">
						{props.intro}
					</div>
				</div>
			</div>
		</div>
	);
}

export default Banner;
