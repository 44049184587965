import React from 'react'
import TeamLeaderProfile from './teamleader/TeamLeaderProfile'
import SpeakerProfile from './speaker/SpeakerProfile'
import SocialEntrepreneurProfile from './socialentrepreneur/SocialEntrepreneurProfile'

const EdmundsProfile = () => {
	return(
		<div>
			<div className="row justify-content-center mb-5 pb-2">
				<div className="col-md-12 heading-section ftco-animate">
					<h2 className="mb-4">About Edmund.</h2>
					{/* <p>WHO IS EDMUND?</p> */}
                    <p>
						<strong>Edmund Walusimbi</strong> as an individual, can best be described from three main angles: - as a <b>Team Leader</b>, a <b>Motivational Speaker</b> and as a <b>Social Entrepreneur</b>.
					</p>
				</div>
			</div>
			<TeamLeaderProfile />
			<SpeakerProfile />
			<SocialEntrepreneurProfile />
		</div>
	);
}

export default EdmundsProfile;