import React from 'react'
import Banner from './../banner/Banner'
import Books from './Books'
import BookPreview from './modals/BookPreview'
import BookDetails from './modals/BookDetails'

const BooksCollection = () => {
	return(
		<div>
            <Banner
                image="https://www.edmundwalusimbi.com/images/personalpics/BDW_5139.jpg"
                intro={<>
                    <h2 className="subheading">Edmund Walusimbi</h2>
					<h1 className="mb-4">As an Author.</h1>
                </>}
            />
            <section className="ftco-section">
				<div className="container">
					<Books />
				</div>
			</section>
			{/* <BookPreview />
			<BookDetails /> */}
		</div>
	);
}

export default BooksCollection;