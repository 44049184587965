import { React, useEffect } from 'react'
import { GalleryAlbumsList } from './../images/GalleryAlbumsList'
import EdmundViewImage from './EdmundViewImage'
import baguetteBox from 'baguettebox.js'
import './baguetteBoxScript.js'
import { useParams } from 'react-router-dom'

const EdmundsAlbumGallery = () => {

	const { keyid } = useParams()
	const selectedalbum = GalleryAlbumsList.filter(album => album.keyvalue === keyid).map(filteredalbum => (
		filteredalbum.name
	))

	useEffect(() => {
		baguetteBox.run('.tz-gallery')
	});

	return(
		<div>
            <div className="row justify-content-center mb-5 pb-2">
				<div className="col-md-12 heading-section text-center ftco-animate">
                	<p><strong>{selectedalbum}</strong></p>
                	<p>Below are some of the pictures showing the work the <strong>Edmund</strong> has been doing.</p>
				</div>
			</div>
			<div class="row">
                <div class="tz-gallery">
					<div class="row">
						{GalleryAlbumsList.filter(album => album.keyvalue === keyid).map(filteredalbum => (
							filteredalbum.contents.map(images => (
								<EdmundViewImage
									image={images.path}
									name={images.details}
								/>
							))
						))}
					</div>
				</div>
			</div>
			<div className='row justify-content-center'>
				<p><strong>No more images to display.</strong></p>
			</div>
		</div>
	);
}

export default EdmundsAlbumGallery;