import React from 'react'
import Article from './../../timeline/Article'

let happening = [{
    keyvalue: 0,
    title: 'The ABLE Mobile Insurance Application.',
    date: '2018',
    detail: [<>Edmund exhibited on the 8th Annual Open Day about THE ABLE MOBILE INSURANCE APPLICATION. The team also presented in The Hult Prize competitions where they obtained plenty of feedback on how to further improve their innovation.</>]
}, {
    keyvalue: 1,
    title: 'The Motivator Forum.',
    date: '2018',
    detail: [<>Edmund also took on the mantle to improve his public speaking by enrolling for training under <b>The Motivator Forum</b> by <b>Mr. Pepe Miambo</b>, an international motivational speaker with over 20 years`` experience. With this skill, he was able to further upgrade his motivational speaking.</>]
}, {
    keyvalue: 2,
    title: 'The Birth of The Makerere Gavel Club.',
    date: 'February, 2019',
    detail: [<>In February 2019, Edmund was among the pioneering team that started up <b>The Makerere Gavel Club</b>, an affiliate of Toastmasters International, a world leader in communication and leadership skills.<br/>He is currently the former Vice President Education in charge of planning programs to improve the public speaking skills of members.</>]
}, {
    keyvalue: 3,
    title: 'The Mentorship Class 2019.',
    date: '2019',
    detail: [<>Edmund also served as a coach in The Mentorship Class 2019 where he was involved in the mentoring of the students within this cohort.<br/>The Mentorship Class (<a href="https://thementorshipclass.org/">www.thementorshipclass.org</a>) is a six month`s training that uses the transformative power of mentoring to groom a new generation of revolutionary leaders and entrepreneurs.<br/>This class ended on <b>July, 6th</b> with the graduation event at Skys Hotel Naguru where he was awarded a certificate of distinguished service by the <b>United States Ambassador to Uganda</b>, <b>Deborah R. Malac</b>.</>]
}, {
    keyvalue: 4,
    title: 'The Birth of The Secret Initiative.',
    date: '2019',
    detail: [<>It was during this period that his youth program grew with presentations in more schools such as <b>City High School Kololo</b> and <b>Mandela S.S Hoima</b>. He then enrolled on branding it as <b>The Secret Initiative</b>.<br/><b>The Secret Initiative</b> {/* (<a href="www.thesecretinitiative.com">www.thesecretinitiative.com</a>) */}is a youth program that has reached over 10,000 youth with a vision of inspiring youth globally to unravel their unique strengths through self-awareness, career discovery and opportunity mapping.</>]
} ];

const YearTwo = () => {
	return(
		<div>
			<div className="row justify-content-center mb-5 pb-2">
                <div className="col-md-12 heading-section ftco-animate">
                {/* <h2 className="mb-4">ORDER OF EVENTS.</h2> */}
                <p><strong>YEAR TWO (THE YEAR OF MUSCLE).</strong></p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
				    <div className="timeline-centered">
                        {happening.map(value => (
                            <Article key={value.keyvalue} title={value.title} date={value.date} detail={value.detail} ></Article>
                        ))}
                        <article className="timeline-entry begin animate-box" data-animate-effect="fadeInBottom">
                            <div className="timeline-entry-inner">
                                <div className="timeline-icon color-none">
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
		</div>
	);
}

export default YearTwo;