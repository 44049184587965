import React from 'react'
import {Helmet} from "react-helmet"
import Accordion from './accordion/Accordion';
import { useParams } from 'react-router-dom'
import { Accomplishments } from './accordion/Accomplishments';

const AccomplishmentDetails = () => {
    const { keyId } = useParams()
    const selectedrole = Accomplishments.filter(role => role.keyvalue === keyId)//.map(filteredalbum)

	return(
		<section className="ftco-section">
            <Helmet>
                <title>Edmund Walusimbi | {keyId}</title>
                <meta name="description" content="Helmet application" />
            </Helmet>
			<div className="container">
                <div className="row">
                    <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                        <span className="heading-meta">Accomplishment Details.</span>
                        <h2 className="colorlib-heading animate-box">{selectedrole[0].position}</h2>
                    </div>
                </div>
                <div className="row">
                    <p>{selectedrole[0].intro}</p>
                </div>
                <Accordion id={keyId} />
            </div>
		</section>
    );
}



export default AccomplishmentDetails;