import React from 'react'
import Article from './../../../timeline/Article'

let happening = [{
    keyvalue: 0,
    title: 'The beginning of the journey.',
    date: 'July, 2017',
    detail: [<>Edmund begun his journey in motivational speaking in <b>July 2017</b> after qualifying to pursue a <b>Bachelor of Science in Mechanical engineering</b> at Makerere University. He decided to use skills he gained during his work experience and involvement in youth programs like <b>The Health and Development Experts</b> and <b>The Mentorship Class</b> to inspire students.</>]
}, {
    keyvalue: 1,
    title: 'The birth of The Secret Initiative.',
    date: '2017-2018',
    detail: [<>That was the birth of <b>The Secret Initiative</b>, a youth program with a vision of inspiring youth globally to unravel their unique strength through self-awareness, career discovery and opportunity mapping.</>]
}, {
    keyvalue: 2,
    title: 'The Secret to Getting Government Sponsorship on Merit.',
    date: 'April, 2018',
    detail: [<>Under The Secret Initiative, published a book entitled <b>THE SECRET TO GETTING GOVERNMENT SPONSORSHIP ON MERIT</b> which was endorsed by <b>The Prime Minister of Buganda kingdom</b>, <b>Mr. Charles Peter Mayiga</b>.</>]
}, {
    keyvalue: 3,
    title: 'The Careers Catalog Conference, 2019',
    date: '2019',
    detail: [<><p>In 2019, <b>The Secret Initiative</b> successfully organized <b>The Careers Catalog Conference</b> where we had over 200 students, 8 exhibitors and partners being Coca-Cola, Isbat University and Makerere Gavel Cub.</p><p>The guest of honor was <b>Mr. Isaiah Masiga</b>, <b>The President of The Global Korean Scholarship Alumni Network</b>. He was accompanied by other renown guest speakers like: -<br/><ul><li><b>Mr. Henry Nsubuga</b>, The Manager of the Guidance and Counseling Department for Makerere University.</li><li><b>Mr. Paul Senyomo</b>, The Chief Executive Officer and Managing Director of Mercantile Credit Bank.</li><li><b>Mrs. Janet Bugembe</b>, a Co-Founder of The Mentorship Class and one of New Vision`s Top 40 under 40 for 2017.</li></ul></p></>]
}, {
    keyvalue: 4,
    title: [<>TED<sup>X</sup> IUEA Event.</>],
    date: [<>20<sup>th</sup> March, 2021</>],
    detail: [<>
        <p>On "day" March, 2021, Edmund had an opportunity to speak at a <b>Ted<sup>x</sup></b> event that was held at the <b>International University of East Africa</b>. In his speech which was titled, <b>What young Africans need to succeed</b> he contrasted between the factors that people usually think guarantee success in life and those that actually help youth in becoming successful.</p>
        <p>
            <a href='https://www.youtube.com/watch?v=u9iCdPFOlKU'>Follow this link to watch the full video on YouTube.</a><br />
            <a href='https://iuea.ac.ug/StudentLife/tedxiuea/mr-edmund-walusimbi/'>Click here</a> to view <b>Edmund's TED<sup>X</sup> IUEA, 2021 profile</b>.
        </p>
    </>]
}, {
    keyvalue: 5,
    title: "The MTN Youth Skilling program",
    date: [<>17<sup>th</sup> September, 2021</>],
    detail: [<>
        <p>On 17<sup>th</sup> September 2021, Edmund was invited to speak at the <b>MTN Youth Skilling program</b> about Impact Tracking as an organization.</p>
    </>]
}, {
    keyvalue: 6,
    title: [<>The Africa Biennial Biosciences Communication (ABBC) Symposium.</>],
    date: [<>20<sup>th</sup> to 24<sup>th</sup> September, year</>],
    detail: [<>
        <p>Edmund coordinated <b>the fourth Africa Biennial Biosciences Communication (ABBC) Symposium</b> which took place for four days (20<sup>th</sup> to 24<sup>th</sup> September) at Serena Hotel.</p>
        <p>Edmund played the role of <b>National Handler for Uganda</b> at this symposium which was a hybrid event that ran with six focus countries (Ethiopia, Kenya, Malawi, Ghana, Nigeria, and Uganda) which were virtually linked to share about the remarkable success that the regions have made in the adoption of biotech crops, advancements, and prospects of genome editing under the theme of <b>“Accelerating Africa`s Biotech Tipping Point: Taking Stock and Celebrating the Gains”</b>.</p>
    </>]
}, {
    keyvalue: 7,
    title: [<>World Engineering Day 2022 celebrations.</>],
    date: [<>4<sup>th</sup> March, 2022</>],
    detail: [<>
        <p>Edmund was part of the organizing team for the World Engineering Day 2022 celebrations that took place on 4th March at Hotel Africana. He coordinated different marketing and communication channels with over 2,000 engineers who were the target market for the event.</p>
    </>]
}, {
    keyvalue: 8,
    title: [<>The GIZ New Chapter event.</>],
    date: [<>5<sup>th</sup> June, 2021</>],
    detail: [<>
        <p>Edmund was <b>Master of Ceremony</b> at the GIZ New Chapter event that was celebrating the success of the <b>Digital Human Rights Lab project</b> under GIZ Uganda.</p>
    </>]
}];

const SpeakingExperience = () => {
	return(
        <div className="to-collapse" id="hiddenEdmundMotivator">
            <div className="row">
                <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
					<span className="heading-meta">Experience</span>
					<h2 className="colorlib-heading animate-box">Work Experience</h2>
				</div>
			</div>
            <div className="row">
                <div className="col-md-12">
				    <div className="timeline-centered">
                        {happening.map(value => (
                            <Article key={value.keyvalue} title={value.title} date={value.date} detail={value.detail} ></Article>
                        ))}
                        <article className="timeline-entry begin animate-box" data-animate-effect="fadeInBottom">
                            <div className="timeline-entry-inner">
                                <div className="timeline-icon color-none">
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SpeakingExperience;