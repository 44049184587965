import React, { Component } from 'react'
import axios from 'axios'
import swal from 'sweetalert'

class BookingForm extends Component {
	constructor() {
		super()
		this.state={
			fname: "",
			surname: "",
			orgname: "",
			email: "",
			telephone: "",
			talktype: "",
			eventtype: "",
			eventlocation: "",
			eventcountry: "",
			eventdate: "",
			eventtime: "",
			eventlength: "",
			time: "",
			date: ""
		}
		this.handleChange = this.handleChange.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
	}

	handleChange(event) {
		const {name,value} = event.target
		this.setState({
			[name]: value
		})
	}

	handleSubmit(event) {
		event.preventDefault()
		axios.post('https://edmundwalusimbi.com/api/public/api/service', {
			fname: this.state.fname,
			surname: this.state.surname,
			orgname: this.state.orgname,
			email: this.state.email,
			telephone: this.state.telephone,
			talktype: this.state.talktype,
			eventtype: this.state.eventtype,
			eventlocation: this.state.eventlocation,
			eventcountry: this.state.eventcountry,
			eventdate: this.state.eventdate,
			eventtime: this.state.eventtime,
			eventlength: this.state.eventlength,
			time: new Date().toLocaleTimeString(),
			date: new Date().toLocaleDateString()
		})
		.then((response) => {
			// alert(response.data)
			swal("Response:", response.data.message, "success")

			// Clearing the form after submitting successfully.
			this.setState({
				fname: "",
				surname: "",
				orgname: "",
				email: "",
				telephone: "",
				talktype: "",
				eventtype: "",
				eventlocation: "",
				eventcountry: "",
				eventdate: "",
				eventtime: "",
				eventlength: "",
				time: "",
				date: ""
			})
		})
		.catch((error) => {
			// console.log(error)
			if(error.message === "Network Error"){
				swal("Error:", "We are having trouble contacting Edmund. Please check your internet connection and try again.", "error")
			}
		})

		axios.post('https://edmundwalusimbi.com/api/public/api/servicenotification', {
			fname: this.state.fname,
			surname: this.state.surname,
			orgname: this.state.orgname,
			email: this.state.email,
			telephone: this.state.telephone,
			talktype: this.state.talktype,
			eventtype: this.state.eventtype,
			eventlocation: this.state.eventlocation,
			eventcountry: this.state.eventcountry,
			eventdate: this.state.eventdate,
			eventtime: this.state.eventtime,
			eventlength: this.state.eventlength,
			time: new Date().toLocaleTimeString(),
			date: new Date().toLocaleDateString()
		})
		.then((response) => {
			// alert(response.data)
			swal("Response:", response.data.message, "success")

			// Clearing the form after submitting successfully.
			this.setState({
				fname: "",
				surname: "",
				orgname: "",
				email: "",
				telephone: "",
				talktype: "",
				eventtype: "",
				eventlocation: "",
				eventcountry: "",
				eventdate: "",
				eventtime: "",
				eventlength: "",
				time: "",
				date: ""
			})
		})
		.catch((error) => {
			// console.log(error)

			if(error.message === "Network Error"){
				swal("Error:", "We are having trouble contacting Edmund. Please check your internet connection try again.", "error")
			}else{
				swal("Error:", error.message, "error")
			}
		})
	}

	render() {
		return(
			<div className="col-md-10 col-md-offset-1 col-md-pull-1 animate-box" data-animate-effect="fadeInRight">
				<p><b>Please fill in the form below to submit your request for Edmund`s services.</b></p>
				<form id="booked" name="booked" onSubmit={this.handleSubmit}>
					<div className="form-group">
						<input
							type="text"
							id="fname"
							name="fname"
							value={this.state.fname}
							className="form-control"
							placeholder="First Name"
							onChange={this.handleChange}
							required
						/>
					</div>
					<div className="form-group">
						<input
							type="text"
							id="surname"
							name="surname"
							value={this.state.surname}
							className="form-control"
							placeholder="Surname"
							onChange={this.handleChange}
							required
						/>
					</div>
					<div className="form-group">
						<input
							type="text"
							id="orgname"
							name="orgname"
							value={this.state.orgname}
							className="form-control"
							placeholder="Organization Name (Optional)"
							onChange={this.handleChange}
						/>
					</div>
					<div className="form-group">
						<input
							type="text"
							id="email"
							name="email"
							value={this.state.email}
							className="form-control"
							placeholder="Email Address"
							onChange={this.handleChange}
							required
						/>
					</div>
					<div className="form-group">
						<input
							type="number"
							id="telephone"
							name="telephone"
							value={this.state.telephone}
							className="form-control"
							placeholder="Telephone / WhatsApp contact"
							onChange={this.handleChange}
							required
						/>
					</div>
					<div className="form-group">
						<input
							type="text"
							id="talktype"
							name="talktype"
							value={this.state.talktype}
							className="form-control"
							placeholder="What nature of talk are you interested in?"
							onChange={this.handleChange}
							required
						/>
					</div>
					<div className="form-group">
						<input
							type="text"
							id="eventtype"
							name="eventtype"
							value={this.state.eventtype}
							className="form-control"
							placeholder="Type of Event"
							onChange={this.handleChange}
							required
						/>
					</div>
					<div className="form-group">
						<input
							type="text"
							id="eventlocation"
							name="eventlocation"
							value={this.state.eventlocation}
							className="form-control"
							placeholder="Location of Event"
							onChange={this.handleChange}
							required
						/>
					</div>
					<div className="form-group">
						<input
							type="text"
							id="eventcountry"
							name="eventcountry"
							value={this.state.eventcountry}
							className="form-control"
							placeholder="In which country will the event take place?"
							onChange={this.handleChange}
							required
						/>
					</div>
					<div className="form-group">
						<input
							type="date"
							id="eventdate"
							name="eventdate"
							value={this.state.eventdate}
							className="form-control"
							placeholder="Event Date"
							onChange={this.handleChange}
							required
						/>
					</div>
					<div className="form-group">
						<input
							type="time"
							id="eventtime"
							name="eventtime"
							value={this.state.eventtime}
							className="form-control"
							placeholder="At what time will the event begin?"
							onChange={this.handleChange}
							required
						/>
					</div>
					<div className="form-group">
						<input
							type="number"
							id="eventlength"
							name="eventlength"
							value={this.state.eventlength}
							className="form-control"
							placeholder="For how long will the event last in hours? (OPTIONAL)..."
							onChange={this.handleChange}
						/>
					</div>
					<div className="form-group">
						<button className="btn btn-primary btn-send-message">Submit Request</button>
					</div>
				</form>
			</div>
		);
	}
}

export default BookingForm;