import React, { useContext } from 'react'
import { BookList } from '../BookList';
import BookModalContext from '../../../context/BookModalContext';

const BookDetails = () =>{
	const { chosenBook } = useContext(BookModalContext);
	const selectedBook = BookList.filter(book => book.id === chosenBook)

	return(
			<div id="bookDetailModal" className="modal fade" role="dialog">
				<div className="modal-dialog modal-lg">
			
					<div className="modal-content">
						<div className="modal-header">
							<button type="button" className="close" data-dismiss="modal">&times;</button>
						</div>
						<div className="modal-body">
						
							<section id="skills" className="colorlib-about" data-section="talks">
								<div className="colorlib-narrow-content">
									{selectedBook[0].bookdetails}
								</div>
							</section>
							
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
						</div>
					</div>
			
				</div>
			</div>
	);
}

export default BookDetails;