import React from 'react'
import Article from './../../timeline/Article'

let happening = [{
    keyvalue: 0,
    title: 'The Resilient Africa Network Intern-preneurship training program.',
    date: '2019',
    detail: [<>While proceeding to his 3rd year of study at Makerere University, Edmund joined <b>Resilient Africa Network (RAN)</b> under the <b>2019 Internpreneurship training program</b>. Resilient Africa Network is part of a partnership of 18 universities in 13 African Countries. The Network is led by Makerere University and is one of the eight global development labs under the <b>Higher Education Solutions Network (HESN)</b>.<br/>The RAN core partners include: -<ul><li>Stanford University.</li><li>The George Washington University.</li><li>The Center for Strategic and International Studies.</li></ul>This program was in partnership with <b>The United Social Ventures</b> which trains, mentors and consults youth-led social start-ups in Uganda. Its curriculum bridges the gap between young leaders and global best-practice methodologies.</>]
}, {
    keyvalue: 1,
    title: 'The journey as successful entrepreneurs.',
    date: '5th October, 2019',
    detail: [<>On 5th October 2019, Edmund was part of the alumni committee that organized the event on which they hosted <b>the Co-Founder and Director of Operations of SafeBoda</b>, <b>Mr. Ricky Rapa Thomson</b>, and <b>The Total Start Upper 2019 Winner</b>, <b>Mr. Arnold Muggaga</b>, to share about their journey as successful entrepreneurs.</>]
}, {
    keyvalue: 2,
    title: 'The first Makerere Gavel Club Conference.',
    date: '2019',
    detail: [<>He was part of the organizers for <b>The first Makerere Gavel Club organized conference</b> discussing the <b>"Role of youth in the African Continental Free Trade Agreement"</b>. The African Continental Free Trade Agreement was signed on 21st March 2018 in Kigali Rwanda by 44 of its 55 member states with an aim of boosting trade amongst countries in Africa.<br/>They had renowned speakers concerning this topic like: -<ul><b>Mr. JOHN OKORO, PMP.</b><li>He is a Business Development Manager for Vergnet Group an international Renewable Energy Company headquartered in France.</li><li>He is also an international speaker and Co-Founder and President of Friends of Nigeria (FON), a non-profit Headquartered in Paris, which is one of the largest networks of Nigerian professionals and Entrepreneurs in Europe.</li><br/><b>Ms. DAMALI SSALI.</b><li>She is the Deputy Country Manager at Trade Mark East Africa.</li><br/><b>Ms. MARTHA TENDO</b><li>She is a renowned lawyer.</li><li>She is the Head of Public Relations Crownel Company.</li><br/><b>Mr. Turyagumanawe Elijah</b><li>He is the TV host of the Nbs Tv Business Perspective program.</li></ul></>]
}, {
    keyvalue: 3,
    title: 'The Best Young Motivational Orator in 2019.Guest Speaker at The Mentorship Class.',
    date: '1st December, 2019',
    detail: [<>On World AID`s day, 1st December 2019, Edmund was awarded as <b>The Best Young Motivational Orator</b> by <b>The Association of Uganda Public Speakers</b> in a national public speaking contest.</>]
} ];

const YearThree = () => {
	return(
		<div>
			<div className="row justify-content-center mb-5 pb-2">
                <div className="col-md-12 heading-section ftco-animate">
                    {/* <h2 className="mb-4">ORDER OF EVENTS.</h2> */}
                    <p><strong>YEAR THREE (THE BRANDING MILESTONE YEAR).</strong></p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
				    <div className="timeline-centered">
                        {happening.map(value => (
                            <Article key={value.keyvalue} title={value.title} date={value.date} detail={value.detail} ></Article>
                        ))}
                        <article className="timeline-entry begin animate-box" data-animate-effect="fadeInBottom">
                            <div className="timeline-entry-inner">
                                <div className="timeline-icon color-none">
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
		</div>
	);
}

export default YearThree;