import logo from './Loader.svg';
import './Loading.css';

function LoadingScreen() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="EW Logo" />
        <p>
          {/* Edmund Walusimbi<br/> */}
          www.edmundwalusimbi.com
        </p>
      </header>
    </div>
  );
}

export default LoadingScreen;
