import React from 'react'
import ReactPlayer from 'react-player'

const Talk = (props) =>{
	return(
		<div className="col-md-4 col-sm-6 animate-box" data-animate-effect="fadeInRight">
			<div className="blog-entry">
				<a href="#" className="blog-img">
					<ReactPlayer
						url={props.path}
						width="305px"
						height="200px"
					/>
				</a>
				<div className="desc">
					<h3 className="text-center"><strong>{props.title}</strong></h3>
				</div>
			</div>
		</div>
	);
}

export default Talk;