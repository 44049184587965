import React from 'react'
import ReactPlayer from 'react-player'
import SpeakingExperience from './SpeakingExperience';

const Speaker = () => {
	return(
		<div>
            <div className="row justify-content-center mb-5 pb-2">
                <div className="col-md-12 heading-section ftco-animate">
                    <h2 className="mb-4">Edmund as a Speaker.</h2>
                    {/* <p><strong>Edmund as a Speaker.</strong></p> */}
                </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <ReactPlayer
						url={"https://www.youtube.com/watch?v=u9iCdPFOlKU"}
						width="350px"
						height="200px"
					/>
                </div>
                <div className="col-md-8 col-md-push-1">
                    <p><b>Edmund</b> is extremely passionate about inspiring youth and has been able to reach over 10,000 youth in schools and on radio broadcast.</p>
                    <p className="mb-4">
                        Part of his speaking CV is as follows: -
                        <ul>
                            <li><b>TED<sup>X</sup> IUEA</b> speaker in March, 2021.</li>
                            <li>Awarded <b>Best Motivational Orator 2019</b> by the <b>Association of Uganda Public Speakers</b>.</li>
                            <li>He has spoken to over 10,000 youths.</li>
                        </ul>
                    </p>
                </div>
            </div>
			<div className="row animate-box" data-animate-effect="fadeInLeft">
				<div className="col-md-12 mt-4 person text-center">
					<div className="profile-card-5">
						<a href="#" className="btn btn-primary" data-toggle="collapse" data-target="#hiddenEdmundMotivator" aria-expanded="false" aria-controls="hiddenEdmundMotivator">Learn More</a>
						<br/><p>.</p>
					</div>
				</div>
			</div>
            <SpeakingExperience />
		</div>
	);
}

export default Speaker;