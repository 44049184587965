import React, { useState } from 'react'
import './../accomplish.css'
import AccordionItem from './AccordionItem'
import { Accomplishments } from './Accomplishments'

const Accordion = (props) => {
  const [clicked, setClicked] = useState("0")
  const handleToggle = (index) => {
    if (clicked === index) {
      return setClicked("0")
    }
    setClicked(index);
  };
  let id = props.id
    
	return(
        <div>
            <ul className="accordion">
              {Accomplishments.filter(role => role.keyvalue === id).map(filteredrole => (
                filteredrole.contents.map((faq, index) => (
                    <AccordionItem
                    onToggle={() => handleToggle(index)}
                    active={clicked === index}
                    key={index}
                    faq={faq}
                    />
                ))
              ))}
            </ul>
        </div>
    );
}

export default Accordion;