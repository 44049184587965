import React from 'react'
import {
    Outlet 
} from 'react-router-dom'
import './../../components/gallery/galleryArea.css'

const EdmundsGallery = () => {
	return(
		<div>
            <section className="ftco-section">
				<div className="container">
                    <div className="row justify-content-center mb-5 pb-2">
						<div className="col-md-12 heading-section text-center ftco-animate heading-gallery">
                            <h2 className="mb-4">Edmund`s Gallery.</h2>
						</div>
				    </div>
					<Outlet />
				</div>
			</section>
		</div>
	);
}

export default EdmundsGallery;