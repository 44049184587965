import React from 'react'
import Banner from '../../components/banner/Banner'
import EdmundsProfile from '../../components/about/profile/EdmundsProfile'
import CampusLife from '../../components/about/campus/CampusLife'
// import SmallLoader from './../../components/loading/SmallLoader';

const AboutEdmund = () => {
	// const [loading, setLoading] = useState(true)
  
	// useEffect(() => {
	//   setTimeout(() => setLoading(false), 2000)
	// }, [])

	return(
		// <>
		// {loading === false ? (
			<div>
				<Banner
					image="https://www.edmundwalusimbi.com/images/personalpics/naboutedmundbannerpic.jpg"
					intro={<>
						<h2 className="subheading">Who is</h2>
						<h1 className="mb-4">Edmund Walusimbi?</h1>
					</>}
				/>
				<section className="ftco-section">
					<div className="container">
						<EdmundsProfile />
						<CampusLife />
						<div className="row justify-content-center mb-5 pb-2">
								<div className="col-md-12 heading-section ftco-animate">
									<h2 className="mb-4">MILESTONES OF 2020.</h2>
									<p>
										The year 2020 was a unique year in which Edmund was able to reach a number of milestones that he has set. Some of them are;
										<ul>
											<li>Appointed as the <b>Chief Executive Officer</b> for The Mentorship Class.</li>
											<li>Appointed as a <b>StartHub Africa Campus Ambassador</b>.<br/>StartHub Africa is committed to boosting entrepreneurship and innovation in Africa by training students to build scalable and innovative businesses.</li>
											<li>Edmund took on a contract as the <b>Country Resource Person for the Economic Policy Research Institute</b> that was running the International Training Programme ITP322: Social Protection for Sustainable Development. </li>
											<li>Appointed as a <b>World Peace Ambassador under World Peace Tracts</b>, a worldwide organization bound together by common goals and support of World Peace Diplomacy to stop conflicts and release of captives of war. </li>
											<li>Featured by the <b>United Nations Development Solutions Network Youth in the Great Lakes Region</b> during the International Youth Celebrations for 2020.</li>
											<li>Featured by <b>YAMBA Africa</b> for his work in advocating for youth empowerment.</li>
											<li>Edmund was successfully selected out of over 3,000 applicants to become part of the <b>Young African Leaders Initiative (YALI)</b> cohort 41 under the East African Regional Leadership Centre.</li>
											<li>Was invited to become part of the first <b>100 Founding mentors under Manatee Mentor</b>, a Switzerland digital platform that aims at making mentoring simple, trustworthy and accessible to anyone by matching mentors and mentees.</li>
										</ul>
									</p>
								</div>
							</div>
					</div>
				</section>
			</div>
		// ) : (
		// 	<SmallLoader />
		// )}
		// </>
	);
}

export default AboutEdmund;