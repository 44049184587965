import React from 'react'
import Talk from './Talk'
import { Link } from 'react-router-dom'

let talks = [{
    keyvalue: 0,
    path: 'https://www.youtube.com/embed/wNUA2FyoU1o',
    title: 'A Quick Introduction of Edmund Walusimbi.'
}, {
    keyvalue: 1,
    path: 'https://www.youtube.com/watch?v=u9iCdPFOlKU',
    title: 'What young Africans need to succeed.'
}, {
    keyvalue: 2,
    path: 'https://www.youtube.com/embed/sDygFydSaok',
    title: 'The Passion Resource.'
}/* }, {
    keyvalue: 3,
    path: 'https://www.youtube.com/embed/4PMmDWHbwsk',
    title: 'What is Your One Thing?'
} */ ];

const TalkSummary = () =>{
	return(
		<div>
			<div className="row justify-content-center mb-5 pb-2">
				<div className="col-md-12 heading-section ftco-animate">
					<h2 className="mb-4">My Talks.</h2>
					<p><strong>Catch all my keynote album releases on my YouTube channel, <a href="https://www.youtube.com/channel/UCuUt0FlVGBiBGVFlSEVusEQ"><i className="icon-youtube"></i> Edmund Walusimbi</a>.</strong></p>
				</div>
			</div>
			<div className="row">
				{talks.map(value => (
                	<Talk key={value.keyvalue} path={value.path} title={value.title} ></Talk>
                ))}	
			</div>
			<div className="row animate-box" data-animate-effect="fadeInLeft">
				<div className="col-md-12 mt-4 person text-center">
					<div className="profile-card-5">
                        <Link to="/Speaker" className="btn btn-primary">View More Talks</Link>
						<br/><p>.</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default TalkSummary;