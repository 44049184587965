import React from 'react'
import EdmundsAlbumSelect from './EdmundsAlbumSelect'
import './../albumscss/gallery_grid.css'
import './../albumscss/gallery-grid.css'
import { GalleryAlbumsList } from './../images/GalleryAlbumsList'

const GalleryAlbums = () => {
	return(
		<div>
            <div className="text-center">
                <p>Select any of the albums to view any of <strong>Edmund`s</strong> pictures.</p>
            </div>
            <div class="row">
                <div class="tz-gallery">
                    <div class="row">
                        {GalleryAlbumsList.map(value => (
                            <EdmundsAlbumSelect
                                keyid={value.keyvalue}
                                image={value.image}
                                name={value.name} 
                                contents={value.contents} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
	);
}

export default GalleryAlbums;