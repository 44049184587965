import React from 'react'
import Contact from './../../components/contact/Contact'

const ContactEdmund = () => {
	return(
		<div>
            <section className="ftco-section">
				<div className="container">
					<Contact />
				</div>
			</section>
		</div>
	);
}

export default ContactEdmund;