import React from 'react'
import './GalleryCarousel.css'
import GalleryCarousel1 from './images/teamleader8.jpg'
import GalleryCarousel2 from './images/teamleader10.jpg'
import GalleryCarousel3 from './images/teamleader3.jpg'
import GalleryCarousel4 from './images/teamleader2.jpg'
import GalleryCarousel5 from './images/katikirotalk.jpeg'

const GalleryCarousel = () => {
	return(
		<div className="pic-ctn">
    		<img src={GalleryCarousel1} alt="" className="pic"/>
    		<img src={GalleryCarousel2} alt="" className="pic"/>
    		<img src={GalleryCarousel3} alt="" className="pic"/>
    		<img src={GalleryCarousel4} alt="" className="pic"/>
    		<img src={GalleryCarousel5} alt="" className="pic"/>
  		</div>
	);
}

export default GalleryCarousel;