import React from 'react'
import LeadershipHistory from './LeadershipHistory';

const TeamLeaderProfile = () => {
	return(
		<div>
            <div className="row justify-content-center mb-5 pb-2">
                <div className="col-md-12 heading-section ftco-animate">
                    <h2 className="mb-4">Edmund as a Team Leader.</h2>
                    {/* <p><strong>Edmund as a Team Leader.</strong></p> */}
                </div>
            </div>
            <div className="row">
                <div className="col-md-8 col-md-push-1">
                    <p><b>Edmund</b> is a transformational leader who is very passionate about the growth and development of the people he serves with in the different organizations and capacities. He strongly emphasizes teamwork and personal development of his subordinates.</p>
                    <p className="mb-4">
                        He has held a number of responsibilities some of which are: -
                        <ul>
                            <li>C.E.O of <b>The Mentorship Class - 2020/2022</b>.</li>
                            <li>President of The <b>Makerere Engineering Society - 2020/2021</b></li>
                            <li>President of <b>The Mentorship Class Alumni Association - 2019/2020</b>.</li>
                            <li>Vice President Education <b>Makerere Gavel Club - 2019</b>.</li>
                            <li>Coach in <b>The Mentorship Class 2019</b>.</li>
                        </ul>
                    </p>
                </div>
                <div className="col-md-4">
                    <img src="https://www.edmundwalusimbi.com/react/images/about/teamleader.jpg" alt="Edmund Walusimbi as a Team Leader" style={{borderRadius: 5, width: 370}} className="img-responsive" />
            	</div>
            </div>
			{/* <div className="row animate-box" data-animate-effect="fadeInLeft">
				<div className="col-md-12 mt-4 person text-center">
					<div className="profile-card-5">
						<a href="#" className="btn btn-primary" data-toggle="collapse" data-target="#hiddenEdmundTeamLeader" aria-expanded="false" aria-controls="hiddenEdmundTeamLeader">Learn More</a>
						<br/><p>.</p>
					</div>
				</div>
			</div> */}
            <LeadershipHistory />
		</div>
	);
}

export default TeamLeaderProfile;