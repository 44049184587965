import React from 'react'
import Banner from '../../components/banner/Banner'
import Booking from './../../components/contact/Booking'

const BookEdmund = () => {
	return(
		<div>
            <Banner
                image="https://www.edmundwalusimbi.com/images/personalpics/BDW_5163.jpg"
                intro={<>
                    <h2 className="subheading">Book Edmund</h2>
					<h1 className="mb-4">To Speak.</h1>
                </>}
            />
            <section className="ftco-section">
				<div className="container">
					<Booking />
				</div>
			</section>
		</div>
	);
}

export default BookEdmund;