import React from 'react'
import YearOne from './YearOne'
import YearTwo from './YearTwo'
import YearThree from './YearThree'
import YearFour from './YearFour'

const CampusLife = () => {
	return(
		<div>
			<div className="row justify-content-center mb-5 pb-2">
                <div className="col-md-12 heading-section ftco-animate">
                    <h2 className="mb-4">CAMPUS LIFE.</h2>
                    <p><strong>EDMUND`S CAMPUS ARCHIVEMENTS.</strong></p>
                    <p>
                        During his time at campus, Edmund has been involved in a number of activities with a number of individuals and groups. These activities have benefitted both him and the people around him.<br/>
                        Take a look at what he has been doing during the years he spent at campus: -
                    </p>
                </div>
            </div>
			<YearOne />
            <YearTwo />
            <YearThree />
            <YearFour />
		</div>
	);
}

export default CampusLife;