import React from 'react'
import Article from './../../timeline/Article'

let happening = [{
    keyvalue: 0,
    title: 'Tubayo Tours Travel Instagram livestream lockdown series.',
    date: '2020',
    detail: <>Here Edmund shared about how one can use the lockdown time to embark on a journey of self-discovery.</>
}, {
    keyvalue: 1,
    title: 'Allatra TV Middle East International interviews.',
    date: '2020',
    detail: <>This is a series about the Creative Society that envisions global peace. Here Edmund was a guest at one of the sessions and a Co-host on two of the broadcasts.</>
}, {
    keyvalue: 2,
    title: 'Instagram live session with Kamilla Sultanova.',
    date: '2020',
    detail: <><b>Kamilla Sultanova</b> is a global keynote speaker, TedX Speaker, Country Chair for Global Diginity Finland and a member of European Young Leaders 40 under 40.<br/>Here <b>Edmund</b> and her shared about Global Citizenship, Skills, values and Ubuntu.</>
}, {
    keyvalue: 3,
    title: 'Presented on Micro, Small and Medium Sized Enterprise Day under Model Peace Forum`s Instagram broadcast.',
    date: '2020',
    //detail: <>To be filled.</>
}, {
    keyvalue: 4,
    title: 'Instagram Livestream with Tomi Kaukinen.',
    date: '2020',
    detail: <><b>Tomi Kaukinen</b> is a serial entrepreneur, Speaker of the year finalist 2020- Evento Awards and founder of Licence to Fail one of the leading blogs on entrepreneurship in Finland.</>
}, {
    keyvalue: 5,
    title: 'Rotaract Club of Buloba virtual meeting presentation about THE RESET.',
    date: '2020',
    //detail: <>To be filled.</>
}, {
    keyvalue: 6,
    title: 'Founders Live Kampala.',
    date: '2020',
    detail: <>This event was organized by <b>Arielle Africa</b>, a Kenyan organization that aims to create 100,000 jobs in Africa and leading entrepreneurship. <br/>Here <b>Edmund</b> was one of the representatives from Uganda.</>
}, {
    keyvalue: 7,
    title: 'Evaluation of the COVID-19 pandemic on Industrial Productivity in the Manufacturing Sector.',
    date: '2020 - 2021',
    detail: [<><p>Edmund executed a final year <b>Mechanical Engineering research project</b> entitled <b>Evaluation of the COVID-19 pandemic on Industrial Productivity in the Manufacturing Sector</b>.</p> <p>This research paper gave an overview of the COVID-19 pandemic effects on the manufacturing sector within Uganda. During his research he collected data concerning the production rates, power consumption, and labour productivity for four manufacturing industries representing different sectors for three time periods to analyze the effects of the covid-19 pandemic.</p>
    <p><a href='http://dissertations.mak.ac.ug/handle/20.500.12281/11357'>Click here</a> to get access to the <b>final project report</b>.</p>
    {/* <div className="col-md-12 mt-4 person text-center">
    <div className="profile-card-5">
        <a href="http://dissertations.mak.ac.ug/handle/20.500.12281/11357" className="btn btn-primary">More Details</a>
    </div></div> */}</>]
} ];

const YearFour = () => {
	return(
		<div>
			<div className="row justify-content-center mb-5 pb-2">
                <div className="col-md-12 heading-section ftco-animate">
                    {/* <h2 className="mb-4">ORDER OF EVENTS.</h2> */}
                    <p><strong>YEAR FOUR (THE CROWN YEAR).</strong></p>
					<p>The year of 2020 was one filled with alot of uncertainty and lockdowns due to the COVID-19 pandemic. Edmund, during this period, adapted to the tides by engaging in various virtual events such as;</p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
				    <div className="timeline-centered">
                        {happening.map(value => (
                            <Article key={value.keyvalue} title={value.title} date={value.date} detail={value.detail} ></Article>
                        ))}
                        <article className="timeline-entry begin animate-box" data-animate-effect="fadeInBottom">
                            <div className="timeline-entry-inner">
                                <div className="timeline-icon color-none">
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
		</div>
	);
}

export default YearFour;