import React from 'react'
import BookingForm from './forms/BookingForm'
import { Link } from 'react-router-dom'

const Booking = () => {
	return(
		<div>
			<div className="row justify-content-center mb-5 pb-2">
				<div className="col-md-12 heading-section ftco-animate">
                    <h2 className="mb-4">Book Edmund.</h2>
					<p><strong>WHY BOOK EDMUND TO SPEAK?</strong></p>
				</div>
			</div>
			<div className="row">
				<div className="col-md-5">
                    <p>
                        Edmund is very passionate about speaking to people so as to make a positive impact on their lives in more than one way through the knowledge that he passes on to them.<br/>
                        Because of this, he does what he does to the best of his abiilities and makes sure that he positively impacts the lives of the people to whom he speaks.
                    </p>
                    <p>In case you are interested in finding out more about Edmund, please contact him using the link below: -</p>
                    <div className="row animate-box" data-animate-effect="fadeInLeft">
                        <div className="col-md-12 mt-4 person text-center">
                            <div className="profile-card-5">
								<Link to="/Contact" className="btn btn-primary">Get in touch with Edmund</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-7 col-md-push-1">
					<div className="row">
					</div>
					<div className="row">
						<BookingForm />
					</div>
				</div>				
			</div>
		</div>
	);
}

export default Booking;