let GalleryAlbumsList = [{
    keyvalue: "Physical-Talks",
    image: 'https://www.edmundwalusimbi.com/react/images/gallery/pt/Edmundatccc2019.jpg',
	name: 'Physical Talks / Events.',
    contents: [
        {
            path: 'https://www.edmundwalusimbi.com/react/images/gallery/ms/Edmund-as-the-MC-at-an-AIESEC-Event-1.jpg',
            details: 'Edmund as an MC at an AIESEC Event.'
        },
        {
            path: 'https://www.edmundwalusimbi.com/react/images/gallery/ms/Edmund-as-the-MC-at-an-AIESEC-Event.jpg',
            details: 'Edmund as an MC at an AIESEC Event.'
        },
        {
            path: 'https://www.edmundwalusimbi.com/react/images/gallery/ms/Edmund-as-the-MC-at-an-AIESEC-Event-2.jpg',
            details: 'Edmund as an MC at an AIESEC Event.'
        },
        {
            path: 'https://www.edmundwalusimbi.com/react/images/gallery/pt/Edmundatccc2019.jpg',
            details: 'Edmund speaks at the Careers Catalog Conference 2019 at Isbat University.'
        },
        {
            path: 'https://www.edmundwalusimbi.com/react/images/gallery/pt/ccc2019team.jpg',
            details: 'The team at the Careers Catalog Conference 2019.'
        },
        {
            path: 'https://www.edmundwalusimbi.com/react/images/gallery/pt/EdmundatHeadCome2017.jpg',
            details: 'Edmund speaks at the HeadCome workshop 2017.'
        },
        {
            path: 'https://www.edmundwalusimbi.com/react/images/gallery/pt/Edmundatkcbcareers2018.jpeg',
            details: 'Edmund exhibits at the King`s College Budo Careers Day 2018.'
        },
        {
            path: 'https://www.edmundwalusimbi.com/react/images/gallery/pt/EdmundatOBUCdinner2018.jpeg',
            details: 'Edmund speaks at the Old Budonians University Chapter Dinner 2018.'
        },
        {
            path: 'https://www.edmundwalusimbi.com/react/images/gallery/pt/Edmundkcbcareers2018.jpeg',
            details: 'Edmund exhibits at the King`s College Budo Careers Day 2018.'
        },
        {
            path: 'https://www.edmundwalusimbi.com/react/images/gallery/pt/Edmundatmandelass.jpg',
            details: 'Edmund speaks to students of Mandela SS.'
        },
        {
            path: 'https://www.edmundwalusimbi.com/react/images/gallery/pt/Edmundatmukonoparentsss.jpeg',
            details: 'Edmund speaks to students of Mukono Parents SS.'
        },
        {
            path: 'https://www.edmundwalusimbi.com/react/images/gallery/pt/Edmundmandelass.jpg',
            details: 'Edmund speaks to students of Mandela SS.'
        },
        {
            path: 'https://www.edmundwalusimbi.com/react/images/gallery/pt/Edmundwithbudos6.jpg',
            details: 'Edmund speaks to S6 students of King`s College Budo.'
        },
        {
            path: 'https://www.edmundwalusimbi.com/react/images/gallery/pt/kcbcareers2018exhibitors.jpeg',
            details: 'Exhibitors at the King`s College Budo Careers Day 2018.'
        },
        {
            path: 'https://www.edmundwalusimbi.com/react/images/gallery/pt/Edmundatsmak.jpeg',
            details: 'Edmund speaks to students of St. Mary`s College Kisubi.'
        },
        {
            path: 'https://www.edmundwalusimbi.com/react/images/gallery/pt/Edmundatrotaractnaalya.jpeg',
            details: 'Edmund presents at the Roraract Club of Kampala, Naalya.'
        },
        {
            path: 'https://www.edmundwalusimbi.com/react/images/gallery/pt/EdmundfacilitatesatHeadCome.jpg',
            details: 'Edmund presents as a facilitator at the HeadCome workshop 2017.'
        },
        {
            path: 'https://www.edmundwalusimbi.com/react/images/gallery/pt/Edmundmandelas.jpg',
            details: 'Edmund speaks to students of Mandela SS.'
        }
    ]
}, {
    keyvalue: "Milestones",
    image: 'https://www.edmundwalusimbi.com/react/images/gallery/ms/teamleader8.jpg',
	name: 'Milestones.',
    contents: [
        {
            path: 'https://www.edmundwalusimbi.com/react/images/gallery/ms/teamleader2.jpg',
            details: 'The TMC 2019 Alumni Committee.'
        },
        {
            path: 'https://www.edmundwalusimbi.com/react/images/gallery/ms/handshake.jpg',
            details: 'Edmund receives a certificate at the TMC 2017 Graduation event.'
        },
        {
            path: 'https://www.edmundwalusimbi.com/react/images/gallery/ms/katikirotalk.jpeg',
            details: 'A dialogue with the Prime Minister of the Buganda Kingdom.'
        },
        {
            path: 'https://www.edmundwalusimbi.com/react/images/gallery/ms/teamleader8.jpg',
            details: 'TMC 2019 Coach Award.'
        },
        {
            path: 'https://www.edmundwalusimbi.com/react/images/gallery/ms/teamleader10.jpg',
            details: 'TMC 2018 Guest-Speaker Award.'
        },
        {
            path: 'https://www.edmundwalusimbi.com/react/images/gallery/ms/kmaggie.jpg',
            details: 'Edmund with Prof. Maggie Kigozi at TMC 2017.'
        },
        {
            path: 'https://www.edmundwalusimbi.com/react/images/gallery/ms/vusi.jpg',
            details: 'Edmund meets Vusi Thembekwayo.'
        },
        {
            path: 'https://www.edmundwalusimbi.com/react/images/gallery/ms/tedxiuea.jpg',
            details: 'Invitation to speak at TEDXIUEA 2021.'
        },
        {
            path: 'https://www.edmundwalusimbi.com/react/images/gallery/ms/Edmund-features-on-Manatee.jpg',
            details: 'Edmund features on Manatee.'
        }
    ]
    }, {
    keyvalue: "Virtual-Talks",
    image: 'https://www.edmundwalusimbi.com/react/images/gallery/vt/yamba.jpg',
	name: 'Virtual Talks / Events.',
    contents: [
        {
            path: 'https://www.edmundwalusimbi.com/react/images/gallery/vt/IUEA_World_Skills_Day_session.jpg',
            details: 'IUEA World Skills Day session.'
        },
        {
            path: 'https://www.edmundwalusimbi.com/react/images/gallery/vt/World_Skills_Day_Model_Peace_Forum.png',
            details: 'World Skills Day Model Peace Forum.'
        },
        {
            path: 'https://www.edmundwalusimbi.com/react/images/gallery/vt/Rotaract_Mubz_presentation.jpg',
            details: 'Nakawa Makerere University Business School Rotaract presentation.'
        },
        {
            path: 'https://www.edmundwalusimbi.com/react/images/gallery/vt/allatra.jpg',
            details: 'TV interview with Allatra TV Middle East International.'
        },
        {
            path: 'https://www.edmundwalusimbi.com/react/images/gallery/vt/MTN_webinar.jpg',
            details: 'MTN webinar.'
        },
        {
            path: 'https://www.edmundwalusimbi.com/react/images/gallery/vt/instagram.jpg',
            details: 'Edmund in an instagram livestream with TedX speaker, Kamilla Sultanova.'
        },
        {
            path: 'https://www.edmundwalusimbi.com/react/images/gallery/vt/tubayo.jpg',
            details: 'Edmund gets hosted on a Tubayo online event.'
        },
        {
            path: 'https://www.edmundwalusimbi.com/react/images/gallery/vt/Gavel_meeting_at_Rotaract.jpg',
            details: 'Fact Vs Friction Gavel Rotaract session.'
        },
        {
            path: 'https://www.edmundwalusimbi.com/react/images/gallery/vt/license.jpg',
            details: 'Edmund on a License to Fail Instagram livestream.'
        },
        {
            path: 'https://www.edmundwalusimbi.com/react/images/gallery/vt/walusquote.jpg',
            details: 'Edmund, a humanitarian under the Model Peace Forum.'
        },
        {
            path: 'https://www.edmundwalusimbi.com/react/images/gallery/vt/ccc2020.jpg',
            details: 'The Careers Catalog Conference 2020.'
        },
        {
            path: 'https://www.edmundwalusimbi.com/react/images/gallery/vt/yamba.jpg',
            details: 'Edmund is featured as Youth of the Week by Yamba Africa.'
        },
        {
            path: 'https://www.edmundwalusimbi.com/react/images/gallery/vt/edmundrotary.jpg',
            details: 'Edmund was a guest speaker at a Buloba Rotary Club Fellowship.'
        },
        {
            path: 'https://www.edmundwalusimbi.com/react/images/gallery/vt/Hosni_show_epi.jpg',
            details: 'Hosni show episode.'
        },
        {
            path: 'https://www.edmundwalusimbi.com/react/images/gallery/vt/yaliuganda.jpg',
            details: 'YALI Uganda Alumni Deep Dive session.'
        },
        {
            path: 'https://www.edmundwalusimbi.com/react/images/gallery/vt/Edmund-at-the-MSME-day-with-the-Model-Peace-Forum.png',
            details: 'Edmund at the MSME day with the Model Peace Forum.'
        },
        {
            path: 'https://www.edmundwalusimbi.com/react/images/gallery/vt/Edmund-as-a-apeaker-at-the-Smart-Teens-Global-Event.jpg',
            details: 'Edmund as a speaker at the Smart Teens Global Event.'
        },
        {
            path: 'https://www.edmundwalusimbi.com/react/images/gallery/vt/Edmund-as-a-apeaker-at-the-Smart-Teens-Global-Event-1.jpg',
            details: 'Edmund as a speaker at the Smart Teens Global Event.'
        },
        {
            path: 'https://www.edmundwalusimbi.com/react/images/gallery/vt/Edmund-is-interviewed-live-on-Facebook-by-John-Okoro.jpg',
            details: 'Edmund is interviewed live on Facebook by John Okoro.'
        }
    ]
} ]

module.exports = {
    GalleryAlbumsList
}