import React from 'react'
import Article from './../../timeline/Article'

let happening = [{
    keyvalue: 0,
    title: 'The start of the journey as a motivational speaker.',
    date: '2017',
    detail: [<>It is in season when Edmund started his own youth program offering motivational talks to high school students. He started this program with an aim of inspiring students to achieve academic excellence and government sponsorship on merit regardless of their socio-economic status or family background.</>]
}, {
    keyvalue: 1,
    title: 'Motivating high school students.',
    date: '2017',
    detail: [<>In the beginning, he started by visiting schools, such as, <b>Panaroma Secondary School</b> and <b>Mukono High School</b>, which are located in his home district, <b>Mukono</b>. Here he would talk to the students about their God given potential and letting them know that they can archive anything that they put their mind, time and hardwork into.</>]
}, {
    keyvalue: 2,
    title: 'Moving youths and adults altogether.',
    date: '2018',
    detail: [<>He later on proceeded to start speaking to a wider audience of listeners through a youth radio program called <b>AKEEZIMBIRA</b> which can be found on the <b>CBS FM radio station</b>.<br/>He soon thereafter went ahead to also speak to the members of the <b>Rotaract Club of Kampala Naalya</b>.</>]
}, {
    keyvalue: 3,
    title: 'Guest Speaker at The Mentorship Class.',
    date: '2018',
    detail: [<>He was also invited as a Guest speaker in The Mentorship Class 2018 to speak about self-awareness and was awarded a certificate of appreciation by <b>The Prime Minister of Buganda Kingdom</b>, <b>Mr. Charles Mayiga</b>.</>]
}, {
    keyvalue: 4,
    title: 'The Secret to Getting Government Sponsorship on Merit.',
    date: '2018',
    detail: [<>On 8th April, after teaming up with <b>Mwesigwa Enock</b>, he published a book entitled, <b>THE SECRET TO GETTING GOVERNMENT SPONSORSHIP ON MERIT</b>, which was endorsed by <b>The Prime Minister of the Buganda kingdom</b>, <b>Mr. Charles Peter Mayiga</b>.</>]
} ];

const YearOne = () => {
	return(
		<div>
			<div className="row justify-content-center mb-5 pb-2">
                <div className="col-md-12 heading-section ftco-animate">
                    <h2 className="mb-4">ORDER OF EVENTS.</h2>
                    <p><strong>YEAR ONE (THE GENESIS YEAR).</strong></p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
				    <div className="timeline-centered">
                        {happening.map(value => (
                            <Article key={value.keyvalue} title={value.title} date={value.date} detail={value.detail} ></Article>
                        ))}
                        <article className="timeline-entry begin animate-box" data-animate-effect="fadeInBottom">
                            <div className="timeline-entry-inner">
                                <div className="timeline-icon color-none">
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
		</div>
	);
}

export default YearOne;