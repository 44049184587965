import React from 'react'
import Article from './../../../timeline/Article'
import { Link } from 'react-router-dom'
// import './../hiddenSections.css'

let happening = [{
    keyvalue: 0,
    title: 'Current CEO of The Mentorship Class.',
    date: 'November 2020 - September 2022',
    detail: [<><p>The Mentorship Class (<a href="https://thementorshipclass.org/">www.thementorshipclass.org</a>) is a six months training that uses the transformative power of mentoring to groom a new generation of revolutionary leaders and entrepreneurs.</p><p>Edmund currently serves as the C.E.O of The Mentorship Class.</p><div className="col-md-12 mt-4 person text-center">
    <div className="profile-card-5">
        <Link to={"/About-Edmund/C.E.O-The-Mentorship-Class"} className="btn btn-primary">More Details</Link>
    </div></div></>]
}, {
    keyvalue: 1,
    title: 'President of The Makerere Engineering Society.',
    date: 'May 2021 - December 2021',
    detail: [<>The Makerere Engineering Society is a body which was was incorporated in <b>1971</b> to act as an intermediary and a link between the <b>engineering students of Makerere University</b> and <b>engineering practitioners</b>.<div className="col-md-12 mt-4 person text-center">
    <div className="profile-card-5">
        <Link to={"/About-Edmund/Makerere-Engineering-Society-President"} className="btn btn-primary">More Details</Link>
    </div></div></>]
}, {
    keyvalue: 2,
    title: 'President The Mentorship Class Alumni Association.',
    date: 'April 2019 - November 2020',
    detail: [<>Edmund served as The President of their alumni association which offers support and on-going mentorship to those outside the class.</>]
}, {
    keyvalue: 3,
    title: 'Vice President Education, The Makerere Gavel Club.',
    date: 'February 2019 - February, 2020',
    detail: [<>In February 2019, Edmund was among the pioneering team that started up <b>The Makerere Gavel Club</b>, an affiliate of Toastmasters International which is a world leader in communication and leadership skills. He currently serves as The Vice President Education in charge of planning programs to improve the public speaking skills of members.</>]
}, {
    keyvalue: 4,
    title: 'Coach in The Mentorship Class.',  //<b>Head of Public Relations and Communications.</b> OR <b>Coach in The Mentorship Class</b>
    date: 'January, 2019 - July, 2019',
    detail: [<>Edmund also served as a coach in <b>The Mentorship Class, 2019</b> where he was involved in the mentoring of the students within this cohort. The Mentorship Class (<a href="https://thementorshipclass.org/">www.thementorshipclass.org</a>) is a six month`s training that uses the transformative power of mentoring to groom a new generation of revolutionary leaders and entrepreneurs.</>]
}];

const LeadershipHistory = () => {
	return(
        <>
            <div className="row animate-box" data-animate-effect="fadeInLeft">
				<div className="col-md-12 mt-4 person text-center">
					<div className="profile-card-5">
						<a href="#" className="btn btn-primary" data-toggle="collapse" data-target="#hiddenEdmundTeamLeader" aria-expanded="false" aria-controls="hiddenEdmundTeamLeader">Learn More</a>
						<br/><p>.</p>
					</div>
				</div>
			</div>
            <div className="to-collapse" id="hiddenEdmundTeamLeader">
                <div className="row">
                    <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                        <span className="heading-meta">Leadership Roles.</span>
                        <h2 className="colorlib-heading animate-box">Leadership History.</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="timeline-centered">
                            {happening.map(value => (
                                <Article key={value.keyvalue} title={value.title} date={value.date} detail={value.detail} ></Article>
                            ))}
                            <article className="timeline-entry begin animate-box" data-animate-effect="fadeInBottom">
                                <div className="timeline-entry-inner">
                                    <div className="timeline-icon color-none">
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LeadershipHistory;