import React from 'react'
import AboutItem from './AboutItem'
import { Link } from 'react-router-dom'

const AboutSummary = () => {
	return(
		<div>
			<div className="row justify-content-center mb-5 pb-2">
				<div className="col-md-12 heading-section ftco-animate">
					<h2 className="mb-4">About Me.</h2>
					<p>WHO AM I?</p>
				</div>
			</div>
			<div className="row">
				<AboutItem
					image="https://www.edmundwalusimbi.com/react/images/about/teamleader.jpg"
					alternate="Edmund as a team leader."
					title="Team Leader"
					text={<>C.E.O <b>The Mentorship Class</b><br/>President <b>Makerere Engineering Society</b> - 2020/2021<br/>President of <b>The Mentorship Class Alumni Association - 2019/2020</b><br/>V.P Education <b>Makerere Gavel Club - 2019</b><br/>Coach in <b>The Mentorship Class 2019</b></>}
				/>
				<AboutItem
					image="https://www.edmundwalusimbi.com/react/images/about/EdmundWalusimbiTEDx.jpg"
					alternate="Edmund as a speaker."
					title="Speaker"
					text={<><br/>Awarded <b>Best Young Motivational Orator 2019</b> by the <b>Association of Uganda Public Speakers</b>.<br/> I have spoken to over 10,000 youths.<br/></>}
				/>
				{/* <AboutItem
					image="https://www.edmundwalusimbi.com/react/images/about/EdmundWalusimbiTEDx.jpg"
					alternate="Edmund as an author."
					title="Author"
					text={<>Author of a number of books, namely;<ul><li><b>The Secret to Getting Government Sponsorhip on Merit.</b></li></ul></>}
				/> */}
				<AboutItem
					image="https://www.edmundwalusimbi.com/react/images/about/socialentrepreneur.jpg"
					alternate="Edmund as a social entrepreneur."
					title="Social Entrepreneur"
					text={<>Founder of <b>The Secret Initiative</b><br/> Author of <b>The Secret To Getting Government Sponsorship on Merit</b>.<br/><br/>.</>}
				/>
			</div>
			<div className="row animate-box" data-animate-effect="fadeInLeft">
				<div className="col-md-12 mt-4 person text-center">
					<div className="profile-card-5">
						<Link to="/About-Edmund" className="btn btn-primary">Learn More About Edmund</Link>
						<br/><p>.</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AboutSummary;