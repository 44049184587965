import React, { useState, useEffect } from 'react'
import LoadingScreen from './components/loading/LoadingScreen';
// import SmallLoader from './components/loading/SmallLoader';
import PageFormat from './navigation/PageFormat'
import ScrollToTop from './navigation/ScrollToTop'

function App() {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => setLoading(false), 3000)
  }, [])

  return (
    <>
    {loading === false ? (
      <>
        <ScrollToTop />
        <PageFormat />
      </>
    ) : (
      <LoadingScreen />
      // <SmallLoader />
    )}
    </>
  );
}

export default App;