import React, { useState } from 'react'
import Book from './Book'
import BookDetails from './modals/BookDetails';
import BookPreview from './modals/BookPreview';
import { BookList } from './BookList';

const Books = () =>{
	return(
		<div>
			<div>
				<div className="row justify-content-center mb-5 pb-2">
					<div className="col-md-12 heading-section ftco-animate">
						<h2 className="mb-4">My Books.</h2>
						<p><strong>As an author, I believe in sharing my knowledge for the benefit of others. One of the ways that I exercise this is by writing.</strong></p>
					</div>
				</div>
				<div className="row">
					{BookList.map(value => (
						<Book
						bookId={value.id}
						keyId={value.keyvalue}
						bookImage={value.image}
						title={value.booktitle}
						published={value.publicationdate}
						audience={value.targetaudience}
						details={value.bookdetails}
						preview={value.bookpreview}
						/>
					))}
				</div>
			</div>
			<BookDetails />
			<BookPreview />
		</div>
	);
}

export default Books;