export let BookList = [{
    id: "EW001",
    keyvalue: "The-Secret-To-Getting-Government-Sponsorship-On-Merit",
    image: 'https://www.edmundwalusimbi.com/react/images/books/The-Secret-To-Getting-Government-Sponsorship-On-Merit.jpg',
	booktitle: "The Secret To Getting Government Sponsorship On Merit",
    bookdesc: "Within this book is a strategy aimed to enable any senior six candidate to acquire government sponsorship on merit.",
    publicationdate: "April, 2018",
    targetaudience: "School students",
    ugx: "10000",
    usd: "3",
//     bookdetails: [<><div className="row">
//     <div className="col-md-12">
//         <div className="row row-bottom-padded-sm animate-box" data-animate-effect="fadeInLeft">
//             <div className="col-md-12">
//                 <div className="about-desc">
//                     <span className="heading-meta">Edmund`s Books (Book Details).</span>
//                     <h2 className="colorlib-heading"><b>The Secret to Getting Government Sponsorship on Merit.</b></h2>
//                     <p>Within this book is a strategy aimed to enable any senior six candidate to acquire government sponsorship on merit regardless of their socio-economic status or family background. It's based on numerous success principles expounding on virtues like hardwork and disciplines like effective planning.</p>
//                 </div>
//             </div>
//         </div>
//         <div className="row">
//             <div className="col-md-5 animate-box" data-animate-effect="fadeInLeft">
//                 <div className="about-desc">
//                     <p>
//                         <b>Author:</b> Edmund Walusimbi<br />
//                         <b>Editor:</b> Enock Mwesigwa<br />
//                         <b>Date published:</b> April, 2018<br />
//                         <b>Target audience:</b> School students
//                     </p>
//                 </div>
//             </div>
//             <div className="col-md-12 col-md-push-1">
//                 <div className="row">
//                 </div>
//                 <div className="row">
//                     <div className="col-md-10 col-md-offset-1 col-md-pull-1 animate-box" data-animate-effect="fadeInRight">
//                         <p>This book can be found in the following bookshops: -</p>
//                         <ul>
//                             <li>Uganda Bookshop</li>
//                             <li>Mukono Bookshop on Colline house</li>
//                             <li>Sharmic bookshop</li>
//                             <li>Angelina bookshop Masaka branch</li>
//                             <li>Kampala New Tyms bookshop in Old Taxi Pack</li>
//                             <li>Rise and Shine Bookshop in Mukono at Bartar</li>
//                             <li>Jicca Stationeries at Wandegya</li>
//                         </ul>
//                     </div>								  
//                 </div>
//             </div>
//         </div>
//         <div className="row">
//             <div className="col-md-12 mt-4 person">
//                 <p>This book was endorsed by the Prime Minister of Buganda Kingdom Mr. Charles Peter Mayiga.</p>
//                 <div className="profile-card-5 text-center">
//                     <a href="https://drive.google.com/file/d/1qzUquElQM8e-U8-aQSh18W0z_ppf-w0Z/view?usp=sharing" className="btn btn-primary">Endorsement by Katikiro of Buganda</a>
//                     <a href="http://ugandabookshop.co.ug/home/712-the-secret-to-getting-government-sponsorship-on-merit" className="btn btn-primary">Post by Uganda Bookshop</a>
//                 </div>
//             </div>
//         </div>							  
//     </div>
// </div></>],
    bookpreview: [<><div className="row row-bottom-padded-sm animate-box" data-animate-effect="fadeInLeft">
    <p>Within this book is a strategy aimed to enable any senior six candidate to acquire government sponsorship on merit regardless of their socio-economic status or family background. It's based on numerous success principles expounding on virtues like hardwork and disciplines like effective planning. The ball is within your court and you have the potential to achieve what you set your mind to by working smart and holding onto determination all the way to the end. It aims to have several students excel and be among those 4000 plus yearly beneficiaries who are sponsored by the government of Uganda for university education.</p>
    <div className="col-md-12 mt-4 person">
        <p>This book was endorsed by the Prime Minister of Buganda Kingdom Mr. Charles Peter Mayiga.</p>
        <div className="profile-card-5 text-center">
            <a href="https://drive.google.com/file/d/1qzUquElQM8e-U8-aQSh18W0z_ppf-w0Z/view?usp=sharing" className="btn btn-primary">Endorsement by Katikiro of Buganda</a>
            <a href="http://ugandabookshop.co.ug/home/712-the-secret-to-getting-government-sponsorship-on-merit" className="btn btn-primary">Post by Uganda Bookshop</a>
        </div>
    </div>
</div></>]
}, {
    id: "EW002",
    keyvalue: "Enhancing-Your-School-To-Work-Transition",
    image: 'https://www.edmundwalusimbi.com/react/images/books/Enhancing-Your-School-To-Work-Transition.jpg',
	booktitle: "Enhancing Your School To Work Transition",
    bookdesc: "This book shades light on ways you can prepare yourself for the real world after school.",
    publicationdate: "June, 2022",
    targetaudience: "High School students, Tertiary students and Parents",
    ugx: "25000",
    usd: "7",
    // bookdetails: "details",
    bookpreview: [<><div className="row row-bottom-padded-sm animate-box" data-animate-effect="fadeInLeft">
        <p>Most times we have dreams of a perfect world in relation to employability where we visualize a seamless journey with no hustle from the end of school to your first desirable form of work.</p>
        <p>
            I hate to blow the bubble but that world does not exist. This book shades light on ways you can prepare yourself for the real world based on key lessons I have learnt and a cocktail of 14 interviews with exceptional practitioners.<br />
            The interview cocktail;
        </p>
        <ul>
            <li>Andrew Bugembe, Managing Director in Neolife GNLD International Limited: Setting out.</li>
            <li>Boris Mugisha Tubayo, Country Director of Tubayo Group: Reaching Mars in 2030.</li>
            <li>John Okoro, Head Business Development for CMR Group for Solar PV and Solar PV Hybrid Solutions: Straight outta Nigeria.</li>
            <li>Christian Hofmann, Team Lead Skills for Social Inclusion at International Labour Organization: Global perspective on preparing for the labour market.</li>
            <li>Masiga Isaiah, Ag. Principal Labour Officer for The Ministry of Gender, Labour, and Social Development: What the MGLSD is doing.</li>
            <li>Timothy Code, Corporate MC, Hip Hop artist, and Digital Influencer: From Law to Entertainment.</li>
            <li>Raymond Malinga, CEO Creatures Animation studio: From Uganda to Disney.</li>
            <li>Ronald Bbosa, President Human Resource Managers Association Uganda: The Human Resource Manager`s perspective.</li>
            <li>Jacob Eyeru, Chairman National Youth Council: Remarks from the National Youth Council.</li>
            <li>Barbara Kasekende, Head of Business Advisory at Uganda Development Bank: Being you.</li>
            <li>Laura Althaus-Mugagga, Co-Founder StartHub Africa: The innovator`s perspective.</li>
            <li>Michael Niyitegeka, Program Director Refactory: Unlocking your tech potential.</li>
            <li>Apollo Muyanja, Project Director for Private Sector Foundation Uganda: Overview of the private sector.</li>
            <li>Paul Senyomo, CEO Mercantile Bank: Grooming a national champion.</li>
        </ul>
    </div></>]
    } ]

// module.exports = {
//     BookList
// }