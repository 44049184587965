import React from 'react'
import './AboutCard.css'

const AboutItem = (props) => {
	return(
		<div className="col-md-4 mt-4 person text-center">
			<div className="card profile-card-5">
				<div className="card-img-block">
					<img className="card-img-top" src={props.image} alt={props.alternate}/>
				</div>
				<div className="card-body pt-0">
					<h3><strong>{props.title}</strong></h3>
					<p className="mb-4">
						{props.text}
					</p>
				</div>
			</div>
		</div>
	);
}

export default AboutItem;