import React from 'react'
import './Timeline.css'

const Article = (props) =>{
	return(
		<article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
            <div className="timeline-entry-inner">
                <div className="timeline-icon color-1">
                    <i className="icon-pen2"></i>
                </div>
                <div className="timeline-label">
                    <h2><b>{props.title}</b> <span>{props.date}</span></h2>
                    <p>{props.detail}</p>
                </div>
            </div>
        </article>
	);
}

export default Article;